import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { Router, RouterLink } from "@angular/router";
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subject} from "rxjs";
import { debounceTime } from 'rxjs/operators';
import * as _ from "lodash";
import {ToastrService} from "ngx-toastr";
import { openDetailPage, isImageResource } from '../../app.utils';

import {
  markdownToElement,
  attachmentIconMapping,
  capturePhoto,
  startCamera,
  isWindowsTablet,
  stopCamera
} from "../../app.utils";
import {UserService} from "../../services/user.service";
import { translate, TranslocoDirective } from "@jsverse/transloco";
import {TranslocoLocaleService} from "@jsverse/transloco-locale"
import { FieldValueToClassPipe } from '../../pipes/field-value-to-class.pipe';
import { ReplaceStrPipe } from '../../pipes/replace-str.pipe';
import { FormatDateTimePipe } from '../../pipes/format-datetime.pipe';
import { FormatDatePipe } from '../../pipes/format-date.pipe';
import { SpecialTitleCasePipe } from '../../pipes/special-title.pipe';
import { NgArrayPipesModule, NgObjectPipesModule } from 'ngx-pipes';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { FieldViewerComponent } from '../field-viewer/field-viewer.component';
import { MapsComponent } from '../maps/maps.component';
import { GalleryDirective } from '../../directives/gallery.directive';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { NgClickOutsideDelayOutsideDirective, NgClickOutsideDirective } from 'ng-click-outside2';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { EditorComponent } from '../editor/editor.component';
import { IconComponent } from '../icon/icon.component';
import { RthighlightDirective } from '../../directives/rthighlight.directive';
import { NgIf, NgClass, NgStyle, NgTemplateOutlet, NgSwitch, NgSwitchCase, NgSwitchDefault, NgFor, DecimalPipe } from '@angular/common';
import { CheckDirective } from '../../directives/check.directive';
declare var $:any

@Component({
    selector: 'field',
    templateUrl: './field.component.html',
    styleUrls: ['./field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, CheckDirective, NgIf, RthighlightDirective, IconComponent, NgClass, NgStyle, NgTemplateOutlet, NgSwitch, NgSwitchCase, EditorComponent, FormsModule, NgxMaskDirective, AutofocusDirective, ReactiveFormsModule, NgClickOutsideDelayOutsideDirective, DatepickerComponent, NgSwitchDefault, AutocompleteComponent, RouterLink, NgFor, GalleryDirective, MapsComponent, FieldViewerComponent, NgClickOutsideDirective, GoogleMap, MapMarker, DecimalPipe, NgArrayPipesModule, NgObjectPipesModule, NgxMaskPipe, SpecialTitleCasePipe, FormatDatePipe, FormatDateTimePipe, ReplaceStrPipe, FieldValueToClassPipe]
})
export class FieldComponent implements OnInit,OnChanges {

  @Input() field;
  @Input() value;
  @Input() record;
  @Input() entity;
  @Input() isTableMode = false;
  @Output() fieldSubmit = new EventEmitter();
  @Output() fieldRecordClick = new EventEmitter();

  @ViewChild('Img') imgTag: ElementRef;
  @ViewChild('textfield') textfield: ElementRef;
  @ViewChild('ImageInput') imgInput: ElementRef;
  @ViewChild('FileInput') fileInput: ElementRef;
  @ViewChild('instance', {static: true}) instance;
  @ViewChild('MapModalRef') mapModalRef: ElementRef;
  @ViewChild('ModalRef') modalRef: ElementRef;
  @HostListener('window:beforeunload', ['$event'])

  beforeunloadHandler(e) {
    if (this.isEdit && !(_.isEmpty(this.value) && _.isEmpty(this.valueControl.value)) && !_.isEqual(this.value, this.valueControl.value)) {
      e = e || window.event;
      // For IE and Firefox prior to version 4
      if (e) {
        e.returnValue = 'Sure?';
      }
      // For Safari
      return 'Sure?';
    }
  }

  videoElement: any;
  window = window;
  isEdit : boolean = false;
  userCanDeletePicture: boolean;
  isPermitted: boolean;
  scrollY: number;
  valueControl;
  currentCoords = {
    lat: 0,
    lng: 0
  };
  modalCoords = {
    lat: 0,
    lng: 0
  }
  modal;
  noPosition = true;
  markerOptions = {};
  mapOptions = {};
  locale=this.translocoLocaleService.getLocale();
  attachmentIconMapping = attachmentIconMapping;
  isVideoModalOpen = false


  constructor(public router: Router,
              private userService: UserService,
              public toastr: ToastrService,
              public modalService: NgbModal,
              private translocoLocaleService: TranslocoLocaleService) { }

  ngOnInit(): void {
    if(this.isTableMode){
      if (this.field.type === 'Checkbox' && this.field.isReadOnly) {
        this.valueControl = new UntypedFormControl({value: this.value, disabled: true});
      }else{
        this.valueControl = new UntypedFormControl(this.value)
      }
    }
    this.checkPicDeleteAbility();
    if (this.field.type == "Location") {
      this.getCurrentPosition();
      this.mapOptions = {
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false
      }
      this.markerOptions = {
        draggable: true
      }
    }
  }

  getCurrentPosition(){
    this.window.navigator.geolocation.getCurrentPosition((position) => {
      this.currentCoords.lat = position.coords.latitude;
      this.currentCoords.lng = position.coords.longitude;
      this.noPosition = false;
    });
  }

  displayCurrentButton(){
    if (JSON.stringify(this.modalCoords) === JSON.stringify(this.currentCoords)) {
      document.getElementById('icon')?.classList.add('d-none');
      document.getElementById('icon')?.classList.remove('d-flex');
    } else {
      document.getElementById('icon')?.classList.add('d-flex');
      document.getElementById('icon')?.classList.remove('d-none');
    }
  }

  changePosition(event) {
    this.modalCoords = {
      lat: event.latLng.toJSON().lat,
      lng: event.latLng.toJSON().lng
    }

    this.displayCurrentButton();
  }

  gotoUserPosition(){
    if(this.noPosition) {
      alert(translate('Merci d\'activer l\’accès à la géolocalisation sur votre navigateur'));
    } else {
      this.modalCoords = {
        lat: this.currentCoords.lat,
        lng: this.currentCoords.lng
      }

      if (this.modal) {
        document.getElementById('icon')?.classList.add('d-none');
        document.getElementById('icon')?.classList.remove('d-flex');
      }
    }
  }

  openModal(modalRef){
    if(this.noPosition && !this.value) {
      alert(translate('Merci d\'activer l\’accès à la géolocalisation sur votre navigateur'));
    } else if (!this.value) {
      this.gotoUserPosition();
    } else {
      this.modalCoords = {
        lat: this.value.lat,
        lng: this.value.lng
      }
    }
    this.modal = this.modalService.open(modalRef, {size: 'lg'});
  }

  mapReady(event) {
    var map = event;
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('icon'));
  }

  checkPicDeleteAbility() {
    this.userService.can('DeleteFieldPicture', this.record).subscribe(userCan => {
      this.userCanDeletePicture = userCan && !this.field.isMandatory;
    }, () => {
    });
  }

  getMarked(txt){
    const wrapper = markdownToElement(txt);
    return wrapper.innerHTML;
  }

  onTextAreaInput(event){
    event.srcElement.rows = Math.ceil((event.srcElement.offsetHeight - 16)/16)
  }

  ngOnChanges(changes) {
    if (changes.record && !this.isEdit) {
      if (this.field.type === 'Checkbox' && this.field.isReadOnly) {
        this.value = changes.record.currentValue.value[this.field.id];
        this.valueControl = new UntypedFormControl({value: this.value, disabled: true});
      } else if (this.field.type === 'Checkbox' && !changes.entity && !this.field.isReadOnly) {
        let currentValue = changes.record.currentValue.value[this.field.id]
        let previousValue = changes.record.previousValue.value[this.field.id]
        if (previousValue !== currentValue || currentValue !== this.value) {
          this.value = changes.record.currentValue.value[this.field.id];
          this.valueControl = new UntypedFormControl(this.value);
        }
      } else {
        this.value = changes.record.currentValue.value[this.field.id];
        this.valueControl = new UntypedFormControl(this.value);
      }
    }
  }

  checkValue(value) {
    if ((this.field.type == "LongText" || this.field.type == "ShortText" || this.field.type == "Number" || this.field.type == "Ressource")) {
      if (this.field.isMandatory && (value === "" || value === null)) {
        this.toastr.error(translate("Ce champ est obligatoire"))
        return false
      } else {
        return true
      }
    } else {
      // add more filters later...
      return true
    }
  }

  submit(value) {
    this.isEdit = false;
    if (_.isEqual(value, this.value)){
      if (this.field.type == 'Location') {
        this.modal.close();
      }
      return this.fieldSubmit.emit(this.value);
    }
    if (!this.checkValue(value)) {
      this.valueControl = new UntypedFormControl(this.value);
      return;
    }
    if ((this.field.type == 'Image' || this.field.type == 'Ressource') && value) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = reader.result as string;
        if (this.field.type == 'Ressource') {
          const filename = value.files[0].name
          this.value = {'content': data.split(',')[1], 'name': filename}
        } else {
          this.value = data
        }
        this.fieldSubmit.emit(this.value);
      };
      reader.readAsDataURL(value.files[0]);
    } else {
      if (this.field.type === 'Users'){
        this.value = _.orderBy(value, ['id'], ['desc']);
        value = value.map(user => user.id);
        this.fieldSubmit.emit(value);
      } else{
        if (this.field.type=== 'MultiSelect'){
          this.value = _.orderBy(value, ['id']);
          value = value.map(choice => choice.id);
          this.fieldSubmit.emit(value);
        }else {
          if (this.field.type == 'Location') {
            this.modal.dismiss();
          }
          this.value = value;
          this.fieldSubmit.emit(value);
        }
      }
    }
  }

  cancel(e) {
    if (e && (Object.values(e.srcElement.classList).indexOf('delete') > -1))return
    if(!this.isTableMode){
      this.isEdit = false;
    }
    this.valueControl.setValue(this.value);
  }

  onResize(){
    if (document.getElementById("input")) {
      document.getElementById("input").setAttribute("rows",Math.ceil((document.getElementById("input").offsetHeight - 16)/16).toString())
    }
  }

  getDisplayClass() {
    if (!this.isPermitted) {
      return 'bg-field-hover-read-only pe-none';
    }
    if (this.field.isReadOnly) {
      return 'cursor-default';
    }
    return 'cursor-pointer opacity-90-hover';
  }

  shouldCheckChild() {
    if (this.value && ['OneToOne', 'InverseOneToOne', 'OneToMany'].indexOf(this.field.type) > -1) {
      const accessLevel = typeof this.value.accessLevel !== 'undefined' ? this.value.accessLevel : 0;
      return accessLevel < this.record.accessLevel;
    }
    return false;
  }


  onDisplayClick(event, value) { 
    this.scrollY = window.scrollY;

    if (!this.field.isReadOnly && (this.field.type === 'User' || this.field.type === 'Users')) {
        this.isEdit = true;
        return;
    }

    if (event.target.tagName.toLowerCase() === 'img') return;

    if (this.field.isReadOnly && ((this.field.type !== 'Image' && this.field.type !== 'Ressource') ||
      (!this.value && (this.field.type === 'Image' || this.field.type === 'Ressource')))) return;

    switch (this.field.type) {
        case 'Image':
            this.value ? this.imgTag.nativeElement.click() : this.imgInput.nativeElement.click();
            break;

        case 'Ressource':
            if (this.value) {
                this.isImageResource(this.value.content) ? this.imgTag.nativeElement.click() : window.open(this.value.content, '_blank');
            } else {
                this.uploadFile();
            }
            break;

        case 'Location':
            this.openModal(this.mapModalRef);
            break;

        case 'Checkbox':
            const checkbox = document.getElementById(`checkbox-${this.field.id}`) as HTMLInputElement;
            const clickedElement = event.target as Element;
        
            if (clickedElement !== checkbox) {
                checkbox.checked = !checkbox.checked;
            }
            
            this.submit(!value);
            break;

        default:
            this.isEdit = true;
            if(this.field.type === 'ShortText') {
              setTimeout(() => {
                const input = document.querySelector(`#input-${this.field.id}`) as HTMLInputElement;
                input.selectionStart = input.selectionEnd
              }, 1);
            }
            break;
    }
}


  uploadFile() {
    this.fileInput.nativeElement.click();
  }

  capturePhoto(modal) {
    const photo = capturePhoto(this.videoElement);
    this.fieldSubmit.emit(photo);
    this.closeModal(modal);
  }

  addPicture($event){
    if (isWindowsTablet() && !this.isVideoModalOpen){
      $event.preventDefault();
      this.openUploadModal();
    }
  }

  uploadPicture(modal){
      this.imgInput.nativeElement.click();
      this.closeModal(modal);
  }

  openUploadModal() {
    const modelRef = this.modalService.open(this.modalRef, {size: 'lg', centered: true});
    this.isVideoModalOpen = true
    this.videoElement = document.getElementById('video');
    startCamera(this.videoElement);
    modelRef.closed.subscribe(() => {
      stopCamera(this.videoElement?.srcObject);
      this.isVideoModalOpen = false
    });
    modelRef.dismissed.subscribe(() => {
      stopCamera(this.videoElement?.srcObject);
      this.isVideoModalOpen = false
    });
  }

  closeModal(modal){
    modal.close();
  }

  onRecordClick(event) {
    if (event.ctrlKey) return;
    event.stopPropagation();
    const evt = {recordId: this.value.id, openModal: this.value.isRecordInModal};
    const urlTree = this.router.parseUrl(this.router.url);
    const primary = urlTree.root.children['primary'];
    const auxiliary = urlTree.root.children['modal'];
    if (auxiliary && auxiliary.segments[2].path == this.value.id) {
      return;
    }
    if (primary && auxiliary) {
      if (primary.segments[0].path == 'record' && primary.segments[1].path == this.value.id) {
        this.fieldRecordClick.emit();
        return;
      }
    }
    openDetailPage(evt, () => {
      this.fieldRecordClick.emit();
    })
  }

  checkIfPropagated() {
    return typeof(this.value) === 'object';
  }

  isImageResource(src: string): boolean {
    return isImageResource(src);
  }
}
