import {Injectable, Injector} from '@angular/core';
import {BaseService} from './base.service';
import {mergeMap} from "rxjs/operators";
import {forkJoin, of} from "rxjs";
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ViewService extends BaseService {
  key = 'views'

  constructor(public injector: Injector) {
    super(injector)
  }

  retrieveObjects(params: object, propagations: string[] = []): any {
    return super.retrieveObjects(params, propagations).pipe(mergeMap((observables$: any) => observables$.length ? forkJoin(...observables$).pipe(
          mergeMap((results: any[]) => of(results.map(result => ({ ...result, value: _.omit(result['value'], ['page']) }))))
        ) : of([])
      )
    );
  }

  createObject(params: object, propagations: string[]=[]): any {
    params['value'] = _.omit(params['value'], 'page')
    return super.createObject(params, propagations);
  }

  updateObject(params: object, propagations: string[]=[]): any {
    params['value'] = _.omit(params['value'], 'page')
    return super.updateObject(params, propagations);
  }
}
