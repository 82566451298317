import {Directive, inject, Input, NgIterable} from '@angular/core';
import {NgForOf} from "@angular/common";

@Directive({
    selector: '[ngForTrackById]',
    standalone: true
})
export class NgForTrackByIdDirective<T extends { id: string | number }> {
  @Input() ngForOf!: NgIterable<T>; // 2

  private ngFor = inject(NgForOf<T>, {self: true});

  constructor() {
    this.ngFor.ngForTrackBy = (index: number, item: T) => item.id;
  }
}
