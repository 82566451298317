import { Injectable, Injector, Inject, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandler as FrontErrorHandler } from '@angular/core';
import * as _ from 'lodash';
import { UserService } from '../services/user.service';
import { Store } from '@ngxs/store';
import { cloneDeep } from 'lodash';
import {Router} from '@angular/router';
import {StateResetAll} from "ngxs-reset-plugin";
import {Cache} from "./cache";
import {LocalService} from "../services/local.service";
import { WebSocketService } from '../services/websocket.service';
import {translate } from '@jsverse/transloco';
import {GtmService} from "../services/gtm.service";
import {NgxIndexedDBService} from "ngx-indexed-db";

@Injectable()
export class ErrorHandler implements FrontErrorHandler {

  fieldMappings = {
    nonFieldErrors: '',
    tokenReset: 'Token',
    username: 'Username',
    color: 'Couleur',
    role: 'Rôle',
    email: 'E-mail',
    password: 'Mot de passe',
    level: 'Niveau',
    title: 'Titre',
    checkpoints_: 'Point',
    roles: 'Rôle',
    confirmation: 'Confirmation mot de passe',
    lastName: 'Nom',
    firstName: 'Prénom',
    value: 'Champs'
  };
  static storetrace = [];
  static olderstate = '';
  static currentPayload = {};
  static currentAction = {};
  lastError: any;
  lastErrorTime: number = 0;

  constructor(@Inject(Injector) private injector: Injector,
              @Inject('Cache') private cache: Cache,
              private gtmService:GtmService,
              private dbService: NgxIndexedDBService,
              private router: Router) { }

  static deepDifference(object, base) {
    function changes(object, base) {
      return _.transform(object, function(result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
        }
      });
    }
    return changes(object, base);
  }

  public static log(state,color,content){
    if (state.includes("payload")){
      this.currentPayload = content
    }
    if (state.includes("next state")){
      if (this.storetrace.length >= 20){
        this.storetrace = this.storetrace.slice(1);
      }
      this.storetrace.push({
        "Action": this.currentAction,
        "Payload": this.currentPayload,
        "State": this.deepDifference(content,this.olderstate)
      })
    }
    if (state.includes("prev state")){
      this.olderstate = cloneDeep(content)
    }
  }

  public static group(message){
    this.currentAction = message
  }

  private get store() {
    return this.injector.get(Store);
  }


  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  private get userService() {
    return this.injector.get(UserService);
  }

  private get webSocketService() {
    return this.injector.get(WebSocketService)
  }

  private get localService() {
    return this.injector.get(LocalService);
  }

  private isString(value) {
    return (typeof value === 'string' || value instanceof String);
  }

  private getMapping(error) {
    if (error === 'Detail' || error === 'detail' || error === 'détails' || error === 'Détails' || error === 'details' || error === 'Details' || error === 'détail' || error === 'Détail') {
      return '';
    }
    return this.fieldMappings[error] || error;
  }

  private prettify(obj, prettifiedErrors = {}) {
    let errors;
    if (obj._body) {
      errors = obj._body;
    } else if (obj.error) {
      errors = obj.error;
    }
    else { // error was thrown
      return {'': obj.message}
    }

    if (obj.status == 0) {
      return { '': translate('Vérifier votre connexion internet')};
    } else if (obj.status == 500) {
      return { '': translate('Une erreur est survenue')};
    } else if (obj.status == 400 && (typeof(obj.error) !== 'object' || (Array.isArray(obj.error) && typeof(obj.error[0]) !== 'object'))) {
      return { '': obj.error};
    }
    else if (obj.error.formData && obj.status == 400 && (typeof(obj.error) == 'object' )) {
        return { '': obj.error.formData.value};
    }
    if (this.isString(errors)) {
      try {
        errors = JSON.parse(errors);
      } catch (exception) {
        return {};
      }
    }
    if (Array.isArray(errors)) { // error is an array of objects
      for (const error of errors) {
        this.prettify({error: error}, prettifiedErrors);
      }
    } else { // error is an object
      for (const error in errors) {
        if(typeof(errors[error]) === "string") {
          prettifiedErrors[this.getMapping(error)] = errors[error];
        }
        else {
          for(let childError of errors[error]) {
            if (typeof(childError) === "object") {
              this.prettify({error: childError, parent: this.getMapping(error)}, prettifiedErrors);
            }
            else {
              prettifiedErrors[this.getMapping(error) + (obj.parent ? (' '+obj.parent) : '')] = childError;
            }
          }
        }
      }
    }
    return prettifiedErrors
  }


  handleError(error) {

    // Get the current time
    const currentTime = Date.now();
    // Check if the same error occurred less than 100ms ago
    if (this.lastError && error.message === this.lastError.message &&
        currentTime - this.lastErrorTime < 100) {
      return;
    }
    // Save the current error and time
    this.lastError = error;
    this.lastErrorTime = currentTime;


    const detailsMsg = ['Detail', 'detail', 'détails', 'Détails', 'details', 'Details', 'détail', 'Détail'];
    const ignoredStatusCodes = [0, 400, 401, 404];

    console.error(error)
    const prettifiedErrors = this.prettify(error);

    for (let e in prettifiedErrors) {
      if (this.toastr) {
        if(this.router.isActive('login', false) && error.status === 403) return;
        if (prettifiedErrors[e] !== "Informations d'authentification incorrectes."){
          if(detailsMsg.includes(e)) {
            this.toastr.error(prettifiedErrors[e], '', { onActivateTick: true, })
          }
          else {
            this.toastr.error(prettifiedErrors[e], e, { onActivateTick: true, })
          }
        }
      }
    }
    this.userService.connectedUser().subscribe(res => {
      if(res) {
        if (error.status == null || error.status === undefined || (!ignoredStatusCodes.includes(error.status) && (error.status < 500 || error.status > 599))) {
          this.gtmService.sendToSentry(error);
        }
      }
    });

    if (!this.router.isActive('login', false)){
      if (error?.error?.detail === "Informations d'authentification non fournies" && error.status === 403){
        this.webSocketService.disconnect();
        this.localService.removeUser();
        this.localService.removeSpace();
        this.cache.clearAll();
        this.dbService.clear("widgetsData").subscribe();
        this.dbService.clear("widgetsFilters").subscribe();
        const workspace = this.localService.getWorkspace();
        this.store.dispatch(
          new StateResetAll()
        ).subscribe(() => {
          if (!workspace) {
            this.router.navigate(['/login']);
          } else {
            this.router.navigate(['/' + workspace]);
          }
        }, () => { });
      }
    }

    throw error;
  }
}
