import { isSubstring, parseUrl, socketCacheInvalidateRules } from '../app.utils';
import * as _ from 'lodash';
import { isObservable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class Cache {

  cache = {};

  constructor() { }

  get(req) {
    return isObservable(this.cache[req.urlWithParams])?this.cache[req.urlWithParams]:_.cloneDeep(this.cache[req.urlWithParams]);
  }

  // conditions is: [{param: 'param', value: 'value'}, {queryParam: 'queryParam', value: 'value'}, {body: 'body', value: 'value'}]
  getContains(model, conditions) {
    let result = [];
    for (var url in this.cache) {

      let parsed = parseUrl(url);

      if (parsed.model !== model) continue;

      let find = true;

      for (let condition of conditions) {
        let value = condition.value;

        if (condition?.param) {
          if (!isSubstring('/'+value+'/', url)) {
            find = false;
          }
        } else if (condition?.body) {
          const body = _.isArray(this.cache[url].body[condition?.body]) ? this.cache[url].body[condition?.body] : [this.cache[url].body[condition?.body]];
          const valueAsArray = _.isArray(value) ? value : [value];
          if (!_.intersection(body, valueAsArray).length){
            find = false;
          }
        } else if (condition?.queryParam) {
          if (!isSubstring(condition?.queryParam + '=' + value, url)) {
            find= false;
          }
        }
      }

      if (find){
        result.push(this.cache[url]);
      }
    }
    return result;
  }

  put(req, event) {
    this.cache[req.urlWithParams] = isObservable(event) ? event : _.cloneDeep(event);
  }

  putWithUrl(req, event) {
    this.cache[req.urlWithParams + event.body.id + '/'] = isObservable(event) ? event : _.cloneDeep(event);
  }

  putWithUrlStr(url, event) {
    this.cache[url] = isObservable(event) ? event : _.cloneDeep(event);
  }

  clearAll(){
    this.cache = {}
  }

  clear(url) {
    delete this.cache[url]
  }

  clearList(model) {
    var parsed;
    for (var url in this.cache) {
      parsed = parseUrl(url)

      if (parsed.model == model && parsed.type == 'List') {
        delete this.cache[url];
      }
    }
  }

  clearContains(model, conditions, obj) {
    for (let url in this.cache) {
      let parsed = parseUrl(url);

      if (parsed.model !== model) continue;

      let remove = true;
      for (let condition of conditions) {
        let ongoingValue = obj.id ? obj[condition[0].ongoing.body] : obj;

        if (condition[1]?.cached?.param) {
          if (!isSubstring('/'+ongoingValue+'/', url)) {
            remove = false;
          }
        } else if (condition[1]?.cached?.body) {
          const body = _.isArray(this.cache[url].body[condition[1].cached.body]) ? this.cache[url].body[condition[1].cached.body] : [this.cache[url].body[condition[1].cached.body]];
          const ongoingvalueAsArray = _.isArray(ongoingValue) ? ongoingValue : [ongoingValue];
          if (!_.intersection(body, ongoingvalueAsArray).length) {
            remove = false;
          }
        } else if (condition[1]?.cached?.queryParam) {
          if (!isSubstring(condition[1].cached.queryParam + '=' + ongoingValue, url)) {
            remove= false;
          }
        }
      }

      if (remove) {
        this.clear(url);
      }
    }
  }

  clearListAttribute(impact, ids) {
    for (let id of ids) {
      if (impact.impacts) {
        let objs = this.getContains(impact.model, [{param: 'id', value: id}]);
        if (objs.length) {
          for (let obj of objs){
            for (let imp of impact.impacts) {
              if (imp.listAttribute) {
                this.clearListAttribute(imp, obj.body[imp.values[0][0].ongoing.body]);
              } else {
                this.clearContains(imp.model, imp.values, obj.body[imp.values[0][0].ongoing.body]);
              }
            }
          }

        }
      }
      this.clearContains(impact.model, impact.values, id);
    }
  }

  invalidateFromStales(record){
    if (record.hasOwnProperty('staleEntities')){
      for (const id of record.staleEntities) {
        this.clearContains('records', [[{ongoing: {}}, {cached: {queryParam: 'entity'}}]], id);
      }
    }
    if (record.hasOwnProperty('staleCheckpoints')){
      for (const id of record.staleCheckpoints) {
        this.clearContains('checkpoints', [[{ongoing: {}}, {cached: {param: 'id'}}]], id);
      }
    }
    if (record.hasOwnProperty('staleRecords')){
      for (const id of record.staleRecords) {
        this.clearContains('records', [[{ongoing: {}}, {cached: {param: 'id'}}]], id);
      }
    }
  }

  invalidateFromNotifications(){
    this.clearContains('notifications', [], null);
  }

  invalidateFromHighlights(content, user){
    if (content.actor && content.actor !== user){
      if (content.record){
        this.clearContains('records', [[{ongoing: {}}, {cached: {param: 'id'}}]], content.record);
      }
      if (content.subclassName === "CreateCommentActivity" || content.subclassName === "UpdateCommentActivity") {
        this.clearContains('comments', [[{ongoing: {}}, {cached: {param: 'id'}}]], content.snapshot);
        this.clearContains('comments', [[{ongoing: {}}, {cached: {queryParam: 'record'}}]], content.record);
      }
      if (content.subclassName === "CreateAttachmentActivity") {
        this.clearContains('attachments', [[{ongoing: {}}, {cached: {param: 'id'}}]], content.snapshot);
        this.clearContains('attachments', [[{ongoing: {}}, {cached: {queryParam: 'record'}}]], content.record);
      }
      if (content.entity){
        this.clearContains('records', [[{ongoing: {}}, {cached: {queryParam: 'entity'}}]], content.entity);
      }
    }
  }

  invalidateFromEdition(object, method, type){
    let model = type === 'edition' ? 'records' : 'checkpoints';
    if (object.id){
      for (const rule of socketCacheInvalidateRules[model].filter(r => r.methods.includes(method))) {
        for (const impact of rule.impacts) {
          if (impact.hasOwnProperty('isValid') && !impact['isValid'](this.cache, object)) { continue; }
          if (impact.type.includes('list')) {
            if (!impact.values) {
              this.clearList(impact.model);
            } else {
              this.clearContains(impact.model, impact.values, object);
            }
          }
          if (impact.type.includes('detail')) {
            if (impact.listAttribute) {
              const ids = object[impact.values[0][0]['ongoing'].body];
              this.clearListAttribute(impact, ids);
            } else {
              this.clearContains(impact.model, impact.values, object);
            }
          }
        }
      }
    }
  }

}
