// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:Host ::ng-deep p {
  display: block;
  align-items: center;
}
:Host ::ng-deep .out {
  transform: translateY(20%);
}
:Host ::ng-deep .emoji-mart {
  font-family: inherit !important;
}
:Host ::ng-deep .emoji-mart-emoji-native {
  font-family: inherit !important;
}
:Host ::ng-deep mention-list .mention-menu > li {
  cursor: pointer;
}
:Host ::ng-deep mention-list .mention-menu > li:hover {
  background-color: #f4f4f4;
}
:Host ::ng-deep mention-list .mention-active > a {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #DDF8FC !important;
  color: #4FC9DA !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/comment/comment.component.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,mBAAA;AAAJ;AAEE;EACC,0BAAA;AAAH;AAEE;EACE,+BAAA;AAAJ;AAEE;EACE,+BAAA;AAAJ;AAGI;EACE,eAAA;AADN;AAGM;EACE,yBAAA;AADR;AAKI;EACE,uDAAA;EACA,oCAAA;EACA,yBAAA;AAHN","sourcesContent":[":Host {\n  ::ng-deep p {\n    display: block;\n    align-items: center;\n  }\n  ::ng-deep .out {\n   transform: translateY(20%);\n  }\n  ::ng-deep .emoji-mart{\n    font-family: inherit !important;\n  }\n  ::ng-deep .emoji-mart-emoji-native{\n    font-family: inherit !important;\n  }\n  ::ng-deep mention-list {\n    .mention-menu>li {\n      cursor: pointer;\n\n      &:hover {\n        background-color: #f4f4f4;\n      }\n    }\n\n    .mention-active>a {\n      transition: color 0.2s ease, background-color 0.2s ease;\n      background-color: #DDF8FC !important;\n      color: #4FC9DA !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
