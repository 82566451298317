import {Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';
import * as moment from 'moment';
import { MomentModule } from 'ngx-moment';
import { IconComponent } from '../icon/icon.component';

@Component({
    selector: 'activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IconComponent, MomentModule]
})
export class ActivityComponent implements OnInit {

  @Input() activity;
  moment = moment;

  constructor() { }

  ngOnInit() {
  }

}
