import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, input, computed} from '@angular/core';
import { translate, TranslocoDirective } from "@jsverse/transloco"
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgClass, NgFor, TitleCasePipe } from '@angular/common';

import { blocksToFlattenFields } from 'src/app/app.utils';

@Component({
    selector: 'ordering',
    templateUrl: './ordering.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, NgIf, NgbDropdown, NgbDropdownToggle, NgClass, NgbDropdownMenu, NgFor, NgbDropdownItem, TitleCasePipe]
})
export class OrderingComponent implements OnInit {
  entity = input<any>();
  value = input<string>();
  initialyDisabled = input<boolean>(false);

  @Input() isMobile: Boolean = false;
  @Input() isRecord: Boolean = false;
  @Output() orderingChange = new EventEmitter();

  orderbyName = computed(() => this.getOrderingName(this.value()));
  orderByFields = computed(() => this.blocksToOrderByFields(this.entity().blocks));

  constructor() { }

  ngOnInit() {
  }

  getOrderingName(value){
    if (!value) {
      return translate('Trier')
    }
    let name = value.startsWith('-')? value.substring(1): value
    let nameFields = this.orderByFields().filter(f=> f.name==name)
    if (nameFields.length){
      return nameFields[0].title
    } else {
      return ''
    }

  }

  blocksToOrderByFields(blocks: any[]): any[]{
    return blocksToFlattenFields(blocks).filter(f => f.hasAscOrdering || f.hasDescOrdering);
  }
}
