import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { LoaderComponent } from '../loader/loader.component';
import { CommentComponent } from '../comment/comment.component';
import { NgIf, NgFor, NgTemplateOutlet } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'comments',
    templateUrl: './comments.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, NgIf, CommentComponent, NgFor, NgTemplateOutlet, LoaderComponent, ConfirmationComponent]
})
export class CommentsComponent implements OnInit, OnChanges {
  @Input() public parentScroll;
  @Input() public recordId;
  @Input() public comments;
  @Input() public user;
  @Input() public total;
  @Input() public checkpointId;

  @Output() public onCreateComment = new EventEmitter();
  @Output() public onUpdateComment = new EventEmitter();
  @Output() public onDestroyComment = new EventEmitter();
  @Output() public onUpdateCommentReaction = new EventEmitter();

  @Output() public onLoadMoreBtnClick = new EventEmitter();


  public readonly PAGE_SIZE = 10;
  public fetching = false;
  public ready = false;
  public page = 1;

  public confirmationModal;
  public commentToDelete;

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any): void {
    if (!this.ready){
      if (changes.comments && changes.comments.currentValue?.length || this.total === 0){
        this.ready = true;
      }
    }
    if (this.fetching){
      this.fetching = false;
    }
  }

  track(index: number, obj): number {
    return obj.id;
  }

  public onLoadMoreClick(){
      this.page++;
      this.fetching = true;
      this.onLoadMoreBtnClick.emit({pageSize: this.PAGE_SIZE, page: this.page})
  }

  onCommentDestroyConfirmationOpen(evt) {
    this.commentToDelete = evt.comment;
    this.confirmationModal = this.modalService.open(evt.confirmationRef);
  }

  commentDestroy() {
    if (this.confirmationModal) {
      this.confirmationModal.close();
      this.confirmationModal = null;
    }

    this.onDestroyComment.emit({commentToDelete: this.commentToDelete})
  }

  onCommentDestroyConfirmationClose() {
    this.confirmationModal.dismiss();
  }

  getRemainingCount() {
    return this.total - this.comments.length;
  }
}