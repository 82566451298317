import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { Select } from '@ngxs/store';
import { AppState } from 'src/app/state/app.state';
import { LocalService } from 'src/app/services/local.service';
import { LoaderComponent } from '../loader/loader.component';
import { SiderComponent } from '../sider/sider.component';
import { HeaderComponent } from '../header/header.component';
import { NgIf, NgStyle, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-default',
    templateUrl: './default.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, HeaderComponent, NgStyle, SiderComponent, LoaderComponent, AsyncPipe]
})
export class DefaultComponent implements OnInit {

  @Input() isModal = false;
  @Select(AppState.sider) sider$;

  constructor(
    public localService: LocalService
  ) { }

  ngOnInit(): void {
    this.localService.setTheme(this.localService.getTheme())
  }

}
