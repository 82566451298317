import { Component, OnInit, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'empty',
    templateUrl: './empty.component.html',
    styleUrls: ['./empty.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class EmptyComponent implements OnInit {
  @Input() text;
  @Input() type;
  constructor() { }
  
  ngOnInit(): void {
  }

}
