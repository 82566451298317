import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  signal
} from '@angular/core';
import {computeColumns, computeColumnsClasses} from "../../app.utils";
import { EmptyComponent } from '../empty/empty.component';
import { LoaderComponent } from '../loader/loader.component';
import { RouterLink } from '@angular/router';
import { CardComponent } from '../card/card.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgIf, NgFor, NgTemplateOutlet } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'cards',
    templateUrl: './cards.component.html',
    styleUrls: ['./cards.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, InfiniteScrollModule, CardComponent, NgTemplateOutlet, RouterLink, LoaderComponent, EmptyComponent]
})
export class CardsComponent implements OnInit{
  @Input() records = [];
  @Input() entity = null;
  @Input() isReady;
  @Input() canCreate = false;
  @Input() field = null;
  @Input() groupBy = false;
  @Input() isCollapsed = false
  @Input() scrollDisabled;
  @Input() allElementsLoaded;
  @Input() isCreatingRecord;
  @Input() parentRecord = null;
  @Input() modalWindow = null;
  @Output() cardsScroll = new EventEmitter();
  @Output() cardsCreateSubmit = new EventEmitter();
  @Output() cardsAddSubmit = new EventEmitter();
  @Output() cardsExpand = new EventEmitter();
  @Output() cardsCardClick = new EventEmitter();

  computedColumns = signal('');
  constructor() { }

  ngOnInit() {
    this.computedColumns.set(this.computColumns());
  }

  onCreateSubmit(evt) {
    this.cardsCreateSubmit.emit(evt);
  }

  onAddSubmit(evt) {
    this.cardsAddSubmit.emit(evt);
  }

  public track(index: number, record): number {
    return record.id;
  }

  computColumns() : string {
    const cols = computeColumns(this.entity.cols);
    return computeColumnsClasses(cols);
  }

  shouldCheckParentRecord(){
    return this.parentRecord && this.parentRecord.accessLevel <= this.entity.accessLevel;
  }
}
