import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'exclude',
    standalone: true
})
export class ExcludePipe implements PipeTransform {

  transform(objects: any[], excludedObjects: any[], property: string): any {
    return objects.filter(obj => !excludedObjects.map(excludedObj => excludedObj ? excludedObj[property] : null).includes(obj[property]));
  }

}
