import {Component, OnInit, EventEmitter, Input, input, Output, ChangeDetectionStrategy, computed, Signal} from '@angular/core';
import { translate, TranslocoDirective } from "@jsverse/transloco"
import { IconComponent } from '../icon/icon.component';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, TitleCasePipe } from '@angular/common';

import { blocksToFlattenFields } from 'src/app/app.utils';

@Component({
    selector: 'groupby',
    templateUrl: './groupby.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, NgbDropdown, NgbDropdownToggle, NgClass, NgbDropdownMenu, NgbDropdownItem, IconComponent, TitleCasePipe]
})
export class GroupbyComponent implements OnInit {
  value = input<string>();
  initialyDisabled = input<boolean>(false);
  entity = input<any>();
  @Input() isMobile: Boolean = false;
  @Input() isRecord: Boolean = false;

  @Output() groupbyChange = new EventEmitter();
  
  groupbyName = computed(() => this.getGroupbyName(this.value()));
  groupbyFields = computed(() => this.blocksToGroupByFields(this.entity().blocks));
  hasSharedSpaces: Signal<boolean> = computed(() => (
                                      this.entity().sharedSpaces.length > 1 
                                      || this.blocksToSpacesFields(this.entity().blocks).length > 0
                                    ));

  constructor() { 
  }

  ngOnInit() {
  }

  getGroupbyName(value) {
    if (!value) {
      return translate('Regrouper')
    }
    let name = value
    let nameFields = this.groupbyFields().filter(f => f.name == name)
    if (nameFields.length) {
      return nameFields[0].title
    }
    else if (this.hasSharedSpaces){
      return  translate('Espace')
    }
    else {
      return ''
    }
  }

  blocksToGroupByFields(blocks: any[]): any[]{
    return blocksToFlattenFields(blocks).filter(f => f.hasGroupby);
  }

  blocksToSpacesFields(blocks: any[]): any[]{
    return blocksToFlattenFields(blocks).filter(f => f.type === 'Spaces');
  }
}
