import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, computed, inject, input, output, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgTemplateOutlet } from '@angular/common';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { ActionComponent } from '../action/action.component';
import { TrackDirective } from 'src/app/directives/track.directive';
import { TranslocoDirective } from '@jsverse/transloco';



@Component({
  selector: 'dynamic-actions',
  standalone: true,
  imports: [ActionComponent, ConfirmationComponent, TrackDirective, TranslocoDirective, NgTemplateOutlet, NgbDropdownItem, NgbDropdownMenu, NgbDropdown],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dynamic-actions.component.html',
  styleUrl: './dynamic-actions.component.scss'
})
export class DynamicActionsComponent implements OnInit {

  entity = input.required<any>();
  space = input.required<any>();
  count = input.required<any>();
  executingActionId = input.required<any>();
  isArchived = input(false);
  isMobile = input(false);

  print = output();
  excelExport = output();
  actionExecute = output<any>();
  archiveConfirmationClose = output();
  archiveConfirmationOpen = output<TemplateRef<any>> ();
  archiveModeToggle = output();
  isArchiveToggle = output();
  
  //isArchived = signal(false);
  listingActions = computed(() => this.entity().actions.filter(a => a.isListing && a.isActive));

  dropdownItemExtraCls = computed(() => this.isMobile() ? 'fs-5 px-2' : 'fs-6 px-3 mt-1');
  linkExtraCls = computed(() => this.isMobile() ? '' : 'text-gray-700');
  iconExtraCls = computed(() => this.isMobile()
                                ? 'fa-light w-25px fs-2 text-center me-1' 
                                : 'fa-duotone w-25px fs-5 text-gray-700'
                        );
  extraTrackID = computed(() => this.isMobile() ? '- Mobile': '')
  
  // TODO: use parent dependency inject if it's possible
  private route = inject(ActivatedRoute);

  constructor(){}
  
  ngOnInit(): void {}

  onActionExecute($event: any) {
    this.actionExecute.emit($event);
  }

}
