import { enableProdMode, APP_INITIALIZER, LOCALE_ID, StaticProvider, COMPILER_OPTIONS, CompilerFactory, CompilerOptions, ViewEncapsulation, Compiler, importProvidersFrom, ApplicationConfig } from '@angular/core';
import { platformBrowserDynamic, JitCompilerFactory } from '@angular/platform-browser-dynamic';

import { environment } from '../environments/environment';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { ErrorHandlingModule } from './error-handling.module';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslocoRootModule } from './transloco-root.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { AutosizeModule } from 'ngx-autosize';
import { NgxFilesizeModule } from 'ngx-filesize';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgClickOutsideDirective, NgClickOutsideAttachOutsideDirective, NgClickOutsideDelayOutsideDirective } from 'ng-click-outside2';
import { MentionModule } from 'angular-mentions';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DragulaModule } from 'ng2-dragula';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment as environment_1 } from 'src/environments/environment';
import { SiderState } from './state/sider.state';
import { ReleasesPageState } from './state/releases-page.state';
import { SendResetPageState } from './state/send-reset-page.state';
import { ResetPageState } from './state/reset-page.state';
import { LoginPageState } from './state/login-page.state';
import { HomePageState } from './state/home-page.state';
import { PreferencesPageState } from './state/preferences-page.state';
import { AdministrationPageState } from './state/administration-page.state';
import { RecordPageState } from './state/record-page.state';
import { NotificationsPageState } from './state/notifications-page.state';
import { RecordsPageState } from './state/records-page.state';
import { AppState } from './state/app.state';
import { NgxsModule } from '@ngxs/store';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { DynamicCardFactoryProvider } from './components/card/card.factory';
import { ReplaceStrPipe } from './pipes/replace-str.pipe';
import { FormatDateTimePipe } from './pipes/format-datetime.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FieldValueToClassPipe } from './pipes/field-value-to-class.pipe';
import { GroupReactionsPipe } from './pipes/group-reactions.pipe';
import { FilterByPipe, PluckPipe, FlattenPipe, NgPipesModule } from 'ngx-pipes';
import { TitleCasePipe, CommonModule, registerLocaleData } from '@angular/common';
import { LocalePipe, DateFormatPipe, MomentModule } from 'ngx-moment';
import { provideCharts, withDefaultRegisterables, BaseChartDirective } from 'ng2-charts';
import { provideEnvironmentNgxMask, IConfig, NgxMaskPipe, NgxMaskDirective } from 'ngx-mask';
import { TranslocoService } from '@jsverse/transloco';
import { SsoGatewayService } from './services/sso-gateway.service';
import { WorkspaceService } from './services/workspace.service';
import { CheckpointsetService } from './services/checkpointset.service';
import { ActivityService } from './services/activity.service';
import { ViewService } from './services/view.service';
import { HttpCancelService } from './services/httpcancel.service';
import { PagesetService } from './services/pageset.service';
import { WebSocketService } from './services/websocket.service';
import { UserService } from './services/user.service';
import { TagService } from './services/tag.service';
import { RoleService } from './services/role.service';
import { RecordService } from './services/record.service';
import { PageService } from './services/page.service';
import { EntityService } from './services/entity.service';
import { CommentService } from './services/comment.service';
import { ChoiceService } from './services/choice.service';
import { CheckpointService } from './services/checkpoint.service';
import { CheckpointAttachmentsService } from './services/checkpoint-attachments.service';
import { AttachmentService } from './services/attachment.service';
import { SpaceService } from './services/space.service';
import { WidgetService } from './services/widget.service';
import { ActionService } from './services/action.service';
import { Cache } from './classes/cache';
import { I18NInterceptor } from './interceptors/i18-n.interceptor';
import { HttpCancelInterceptor } from './interceptors/cancel.interceptor';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { CsrfInterceptor } from './interceptors/csrf.interceptor';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { EnvService } from './services/env.service';
import { Injector } from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import localeCn from '@angular/common/locales/zh';


registerLocaleData(localeFr);
registerLocaleData(localeCn, 'cn');

const compilerOptions: CompilerOptions = {
  defaultEncapsulation: ViewEncapsulation.None,
};

export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler([compilerOptions]);
}

export const RUNTIME_COMPILER_PROVIDERS: StaticProvider[] = [
  { provide: COMPILER_OPTIONS, useValue: compilerOptions, multi: true },
  {
    provide: CompilerFactory,
    useClass: JitCompilerFactory,
    deps: [COMPILER_OPTIONS],
  },
  { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] },
];

export const maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = null;


import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';

const dbConfig: DBConfig = {
  name: 'creator',
  version: 1,
  objectStoresMeta: [
    {
      store: 'widgetsData',
      storeConfig: {keyPath: 'id', autoIncrement: true},
      storeSchema: [
        {name: 'id', keypath: 'id', options: {unique: true}},
        {name: 'data', keypath: 'data', options: {unique: false}}
      ]
    },
    {
      store: 'widgetsFilters',
      storeConfig: {keyPath: 'id', autoIncrement: true},
      storeSchema: [
        {name: 'id', keypath: 'id', options: {unique: true}},
        {name: 'filters', keypath: 'filters', options: {unique: false}}
      ]
    }]
};

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(NgxsReduxDevtoolsPluginModule.forRoot(), BrowserModule, AppRoutingModule, NgbModule, NgbPopoverModule, HttpClientJsonpModule, NgxsModule.forRoot([
            AppState,
            RecordsPageState,
            NotificationsPageState,
            RecordPageState,
            AdministrationPageState,
            PreferencesPageState,
            HomePageState,
            LoginPageState,
            ResetPageState,
            SendResetPageState,
            ReleasesPageState,
            SiderState,
        ], { developmentMode: !environment.production }), FormsModule, CommonModule, ReactiveFormsModule, NgPipesModule, ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }), BaseChartDirective, MomentModule, NgSelectModule, NgxFileDropModule, DragulaModule.forRoot(), InfiniteScrollModule, MentionModule, NgClickOutsideDirective, NgClickOutsideAttachOutsideDirective, NgClickOutsideDelayOutsideDirective, GoogleTagManagerModule.forRoot({
            id: environment.GTM_TRACKING_ID,
        }), NgxMaskDirective, NgxMaskPipe, NgxFilesizeModule, AutosizeModule, GoogleMapsModule, TranslocoRootModule, ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }), NgCircleProgressModule.forRoot(), NgxPanZoomModule, ErrorHandlingModule.forRoot(), DragAndDropModule, NgxIndexedDBModule.forRoot(dbConfig),),
        {
            provide: APP_INITIALIZER,
            useFactory: (envService: EnvService) => () => envService.init(),
            deps: [EnvService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpCancelInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: I18NInterceptor, multi: true },
        HttpClientJsonpModule,
        HttpClientModule,
        { provide: 'Cache', useClass: Cache },
        { provide: 'Queue', useClass: Cache },
        { provide: 'ActionService', useClass: ActionService },
        { provide: 'WidgetService', useClass: WidgetService },
        { provide: 'SpaceService', useClass: SpaceService },
        { provide: 'AttachmentService', useClass: AttachmentService },
        {
            provide: 'CheckpointAttachmentsService',
            useClass: CheckpointAttachmentsService,
        },
        { provide: 'CheckpointService', useClass: CheckpointService },
        { provide: 'ChoiceService', useClass: ChoiceService },
        { provide: 'CommentService', useClass: CommentService },
        { provide: 'EntityService', useClass: EntityService },
        { provide: 'PageService', useClass: PageService },
        { provide: 'RecordService', useClass: RecordService },
        { provide: 'RoleService', useClass: RoleService },
        { provide: 'TagService', useClass: TagService },
        { provide: 'UserService', useClass: UserService },
        { provide: 'WebSocketService', useClass: WebSocketService },
        { provide: 'PagesetService', useClass: PagesetService },
        { provide: 'HttpCancelService', useClass: HttpCancelService },
        { provide: 'ViewService', useClass: ViewService },
        { provide: 'ActivityService', useClass: ActivityService },
        { provide: 'CheckpointsetService', useClass: CheckpointsetService },
        { provide: 'WorkspaceService', useClass: WorkspaceService },
        { provide: 'SsoGatewayService', useClass: SsoGatewayService },
        {
            provide: LOCALE_ID,
            useFactory: (translocoService: TranslocoService) => {
                return translocoService.getActiveLang();
            },
            deps: [TranslocoService],
        },
        provideEnvironmentNgxMask(maskOptions),
        provideCharts(withDefaultRegisterables()),
        LocalePipe,
        DateFormatPipe,
        TitleCasePipe,
        FilterByPipe,
        PluckPipe,
        FlattenPipe,
        NgxMaskPipe,
        FieldValueToClassPipe,
        GroupReactionsPipe,
        FormatDatePipe,
        FormatDateTimePipe,
        ReplaceStrPipe,
        DynamicCardFactoryProvider,
        ...RUNTIME_COMPILER_PROVIDERS,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
    ]
}
