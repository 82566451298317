import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'flattenEntities',
    standalone: true
})
export class FlattenEntitiesPipe implements PipeTransform {
    transform(grouped_entities: any){
        let flat_entities = [];
        let pagesets = _.cloneDeep(grouped_entities)
        for (const e in pagesets){
            if (e !== "undefined"){
                pagesets[e].type = "pageset"
                for (const prop in pagesets[e]["0"].pageset){
                    pagesets[e][prop] = pagesets[e]["0"].pageset[prop]
                }
                flat_entities.push(pagesets[e]);
            } else {
                for (const prop in pagesets[e]){
                    flat_entities.push(pagesets[e][prop])
                }
            }
        }
        return flat_entities;
    }
}