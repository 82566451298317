import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { NgClickOutsideDelayOutsideDirective } from 'ng-click-outside2';
import { AutosizeModule } from 'ngx-autosize';
import { NgSwitch, NgSwitchCase, NgIf } from '@angular/common';

@Component({
    selector: 'field-main',
    templateUrl: './field-main.component.html',
    styleUrls: ['./field-main.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [FormsModule, NgSwitch, NgSwitchCase, AutosizeModule, ReactiveFormsModule, NgIf, NgClickOutsideDelayOutsideDirective, AutocompleteComponent]
})
export class FieldMainComponent implements OnInit {
  @Input() value;
  @Input() field;

  @Output() fieldMainSubmit = new EventEmitter();

  valueControl;
  isEdit : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes) {
    this.valueControl = new UntypedFormControl(this.value);
  }

  cancel() {
    this.isEdit = false;
    this.valueControl.setValue(this.value);
  }

  onSubmit(value){
    if (this.field.type == 'User' && value) {
      this.isEdit = false
      this.value = value;
      this.fieldMainSubmit.emit(value);
    } else {
    if (this.value==this.valueControl.value) return;
    if (!this.valueControl.value) { this.valueControl.setValue(this.value); return;}
    this.fieldMainSubmit.emit(this.valueControl.value)
    }
  }
}
