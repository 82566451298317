import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { attachmentIconMapping, imageIconMapping, isImageResource } from '../../app.utils';
import { translate, TranslocoDirective } from "@jsverse/transloco"
import { ReplaceStrPipe } from '../../pipes/replace-str.pipe';
import { NgxFilesizeModule } from 'ngx-filesize';
import { MomentModule } from 'ngx-moment';
import { NgArrayPipesModule, NgObjectPipesModule } from 'ngx-pipes';
import { CheckDirective } from '../../directives/check.directive';
import { RthighlightDirective } from '../../directives/rthighlight.directive';
import { UploadComponent } from '../upload/upload.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'file',
    templateUrl: './file.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, NgIf, UploadComponent, RthighlightDirective, CheckDirective, NgArrayPipesModule, NgObjectPipesModule, MomentModule, NgxFilesizeModule, ReplaceStrPipe]
})
export class FileComponent implements OnInit {
  @Input() mode;
  @Input() attachment;

  @Output() fileUploadSelect = new EventEmitter();
  @Output() fileDelete = new EventEmitter();

  @ViewChild("fileRef") fileRef: ElementRef;


  attachmentIconMapping = attachmentIconMapping;
  imageIconMapping = imageIconMapping;

  constructor() {
  }

  ngOnInit() {
  }

  openFile() {
    if (this.fileRef) {
      this.fileRef.nativeElement.click();
    }
    else {
      window.open(this.attachment.file, '_blank');
    }
  }

  endsWithImage(src: string) {
    return isImageResource(src);
  }

  onFileUploadSelect(file) {
    this.fileUploadSelect.emit(file);
  }

  onFileDelete(id) {
      event.stopPropagation();
      if (window.confirm(translate('Voulez-vous vraiment supprimer le fichier ') + this.attachment.name + ' ?')){
        this.fileDelete.emit(id);
      }
  }

}
