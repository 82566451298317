// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .ng-value-container {
  padding: 5px !important;
}

::ng-deep .ng-dropdown-panel {
  max-width: 300px !important;
}

.comment-dropdown {
  width: 500px !important;
  max-width: 100vw !important;
  max-height: 70vh !important;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  background-clip: border-box;
}

.count-btn {
  top: 2px;
  left: 90%;
  width: 20px !important;
  height: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/checkpoint/checkpoint.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AACA;EACI,2BAAA;AAEJ;;AACA;EACI,uBAAA;EACA,2BAAA;EACA,2BAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,2BAAA;AAEJ;;AACA;EACI,QAAA;EACA,SAAA;EACA,sBAAA;EACA,uBAAA;AAEJ","sourcesContent":["::ng-deep .ng-value-container {\n    padding: 5px !important;\n}\n::ng-deep .ng-dropdown-panel {\n    max-width: 300px !important;\n}\n\n.comment-dropdown {\n    width: 500px !important;\n    max-width: 100vw !important;\n    max-height: 70vh !important;\n    overflow-y: auto;\n    overflow-x: hidden;\n    scrollbar-width: thin;\n    background-clip: border-box;\n}\n\n.count-btn {\n    top: 2px;\n    left: 90%;\n    width: 20px !important;\n    height: 20px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
