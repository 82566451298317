import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { translate, TranslocoDirective }  from "@jsverse/transloco"
import { CheckpointComponent } from '../checkpoint/checkpoint.component';
import { NgForTrackByIdDirective } from '../../directives/ngFor-track-by-id.directive';
import { CheckDirective } from '../../directives/check.directive';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'checkpointset',
    templateUrl: './checkpointset.component.html',
    styleUrls: ['./checkpointset.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, NgIf, FormsModule, AutofocusDirective, CheckDirective, NgFor, NgForTrackByIdDirective, CheckpointComponent]
})
export class CheckpointsetComponent implements OnInit {

  public _checkpointset;
  @Input()
  set checkpointset(val) {
    this._checkpointset = _.cloneDeep(val);
  }
  get checkpointset() {
    return this._checkpointset;
  }

  @Input() public loading;
  @Input() public record;
  @Input() public user;
  @Input() public extension;
  @Input() public checkpointTags;
  @Input() public checkpointsets;
  @Input() public checkpoints;
  @Input() public activeCheckpointStates;
  @Input() public fixingEntities;
  @Input() public isCreatingFixing;
  @Input() public isMain = false;
  @Input() public auditMode = false;
  @Input() public comments;

  @Output() public checkpointsetTitleChange = new EventEmitter();
  @Output() public checkpointsetDelete = new EventEmitter();
  @Output() public checkpointsetClick = new EventEmitter();
  @Output() public onCommentCreate = new EventEmitter();
  @Output() public onCommentUpdate = new EventEmitter();
  @Output() public onCommentDestroy = new EventEmitter();
  @Output() public onCommentReactionUpdate = new EventEmitter();
  @Output() public onClickCheckpointComments = new EventEmitter();
  @Output() public onCommentsLoadMore = new EventEmitter();
  @Output() public onEvaluateCheckpoint = new EventEmitter<any>();
  @Output() public onChangeCheckpointState = new EventEmitter<any>();
  @Output() public onChangeCheckpointComment = new EventEmitter<any>();
  @Output() public onAddCheckpointAttachment = new EventEmitter<any>();
  @Output() public onDeleteCheckpointAttachment = new EventEmitter<any>();
  @Output() public onCreateCheckpointFixingRecord = new EventEmitter<any>();
  @Output() public onClickCardsCard = new EventEmitter();


  public checkpointsetMode = 'read';
  public oldCheckpointsetTitle: string;

  constructor(public toastr: ToastrService,public cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.oldCheckpointsetTitle = String(this.checkpointset.title);
  }

  onSubmit() {
    if (!this.checkpointset.title) {
      this.checkpointset.title = this.oldCheckpointsetTitle;
      this.checkpointsetMode = 'read';
      this.toastr.error(translate('titre ne peut pas être vide'));
      return;
    }
    if(this.oldCheckpointsetTitle.trim() !== this.checkpointset.title.trim()) {
      this.checkpointsetTitleChange.emit(this.checkpointset.title);
      this.oldCheckpointsetTitle = String(this.checkpointset.title);
    } else {
      this.checkpointset.title = this.checkpointset.title.trim();
    }
    this.checkpointsetMode = 'read';
  }
}
