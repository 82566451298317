import { NgClass, NgStyle } from '@angular/common';
import { Component, Output, EventEmitter, input, computed, ChangeDetectionStrategy, effect } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-save',
  standalone: true,
  imports: [NgClass, NgStyle, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, TranslocoDirective],
  templateUrl: './view-save.component.html',
  styleUrl: './view-save.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSaveComponent {
  latestView = input.required<any>();
  currentUser = input.required<any>();
  isMobile = input(false);

  @Output() currentViewSave = new EventEmitter();
  @Output() newViewSave = new EventEmitter();
  @Output() viewReinit = new EventEmitter();

  
  toggleBtnCls = computed(() => (
    this.isMobile()
      ? 'btn-white me-1 shadow-sm text-gray-700'
      : 'btn-sm custom-btn bg-hover-carrot text-hover-white text-carrot fs-8 me-2'
  ));

  toggleBtnIconCls = computed(() => ( this.isMobile() ? 'fs-2' : 'fs-3 text-carrot' ))

  toggleBtnIconStyles = computed(() => (
    this.isMobile() 
      ? { marginLeft: '1.2px' } 
      : { marginLeft: '1.2px', marginBottom: '1px' }
  ))

  menuCls = computed(() => ( this.isMobile() ? 'menu-gray-600 py-4' : 'menu-gray-700 py-2' ))
  menuItemCls = computed(() => ( this.isMobile() ? 'px-3' : 'px-2' ))

  viewHasChanged = computed(() => {
    return this.latestView()?.id != -1 && this.latestView()?.user == this.currentUser()
  });


  constructor(){}

}
