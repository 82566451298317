import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewEncapsulation,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit
} from '@angular/core';
import * as _ from 'lodash';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DoughnutLabel from '../../classes/doughnut-label'
import {LocalService} from "src/app/services/local.service";
import {NgbModal, NgbModalRef, NgbPopover, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {Chart} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import {getActiveFilters} from '../../app.utils'
import {ExistPipe} from '../../pipes/exist.pipe';
import {SafeHTMLPipe} from '../../pipes/safe-html.pipe';
import {NgPipesModule, NgStringPipesModule} from 'ngx-pipes';
import {NgClickOutsideAttachOutsideDirective, NgClickOutsideDelayOutsideDirective} from 'ng-click-outside2';
import {FormComponent} from '../form/form.component';
import {CheckDirective} from '../../directives/check.directive';
import {TrackDirective} from '../../directives/track.directive';
import {ActionComponent} from '../action/action.component';
import {EmptyComponent} from '../empty/empty.component';
import {GalleryDirective} from '../../directives/gallery.directive';
import {MapsComponent} from '../maps/maps.component';
import {GaugeComponent} from '../gauge/gauge.component';
import {IconComponent} from '../icon/icon.component';
import {BaseChartDirective} from 'ng2-charts';
import {NgTemplateOutlet, NgIf, NgStyle, NgFor, NgClass, TitleCasePipe, KeyValuePipe} from '@angular/common';
import {TranslocoDirective} from '@jsverse/transloco';
import {NgClickOutsideDirective} from 'ng-click-outside2';
import {LoaderComponent} from "../loader/loader.component";
import {NgxIndexedDBService} from "ngx-indexed-db";
import {firstValueFrom, forkJoin} from "rxjs";
import {SpecialTitleCasePipe} from "../../pipes/special-title.pipe";


declare var bootstrap: any;
declare var $: any;


@Component({
  selector: 'widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClickOutsideDirective, TranslocoDirective, NgbPopover, NgTemplateOutlet, NgIf, BaseChartDirective, NgStyle, IconComponent, GaugeComponent, MapsComponent, GalleryDirective, EmptyComponent, NgFor, ActionComponent, TrackDirective, CheckDirective, NgbTooltip, NgClass, FormComponent, NgClickOutsideDelayOutsideDirective, TitleCasePipe, KeyValuePipe, NgStringPipesModule, SafeHTMLPipe, ExistPipe, LoaderComponent, NgClickOutsideAttachOutsideDirective, NgPipesModule, SpecialTitleCasePipe]
})
export class WidgetComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public widget;
  @Input() public record;
  @Input() public data;
  @Input() public error = null;
  @Input() public hasCard = true;
  @Input() public globalFilters: any = {};
  @Input() public staleEntities: any = [];
  @Input() public dashboardEntityId = null;
  @Input() public executingActionId = null;
  @Input() public panelIsFullScreen: boolean;

  @Output() public widgetClick = new EventEmitter();
  @Output() public widgetWrite = new EventEmitter();
  @Output() public widgetRefresh = new EventEmitter();
  @Output() public executeAction = new EventEmitter();


  @ViewChild('canvas') public element;
  @ViewChild('gauge') public gauge;
  @ViewChild('carouselElement', {static: false}) public carouselElement!: ElementRef;
  @ViewChild('interactiveActionsPopoverMenu', {read: NgbPopover}) public popover!: NgbPopover;

  public colors = ['#4fc9da', '#e8c444', '#b8d935', '#f06445', '#4F55DA', '#0094b6', '#666666', '#d84280', '#795548', '#9370db', '#3e9623', '#40A497', '#7f8c8d', '#f1c40f',
    '#9b59b6', '#c0392b', '#34495e', '#3498db', '#130f40', '#c7ecee', '#7ed6df', '#e84393', '#fab1a0', '#00b894', '#0984e3', '#fd79a8', '#e1b12c', '#3742fa', '#2f3542',
    '#ff6348', '#eccc68', '#7bed9f', '#ffa502', '#70a1ff', '#2ed573', '#3742fa', '#3742fa', '#A3CB38', '#12CBC4', '#6F1E51', '#FDA7DF', '#B53471', '#006266', '#FFC312',
    '#833471', '#833471', '#009432', '#EA2027', '#12CBC4']

  public barChartPlugins = [ChartDataLabels, DoughnutLabel];
  public chartsOptions: any;
  public computedStyle = getComputedStyle(document.body);
  public theme = this.localService.getTheme();


  public formModalRef: NgbModalRef;
  public fullScreenModalRef: NgbModalRef;


  public localFilters: any = {};
  public activeFilters: any = {};

  public currentTableCell: any;
  public interactiveActions: any;
  public currentWriteForm: any;

  public isLoading: boolean;
  public isFullScreenDisplay = false;
  public isInlineFormActive = false;
  public isWriteFormLoading = false;
  public inlineWriteFormCoordinates = {};
  public interactiveActionsPopoverCoordinates = {};


  constructor(
    public localService: LocalService,
    private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef,
    private dbService: NgxIndexedDBService,
  ) {
  }

  ngOnInit(): void {
    Chart.register(annotationPlugin);
    Chart.defaults.font.size = 15;
    this.showLoader();
    const widgetData$ = this.dbService.getByIndex("widgetsData", 'id', _.toNumber(`${this.record.id}${this.widget.id}`));
    const globalFilters$ = this.dbService.getByIndex("widgetsFilters", 'id', _.toNumber(`${this.record.id}${this.record.entity}`));
    const localFilters$ = this.dbService.getByIndex("widgetsFilters", 'id', _.toNumber(`${this.record.id}${this.widget.id}`));
    const observables$ = forkJoin([widgetData$, globalFilters$, localFilters$]);
    firstValueFrom(observables$).then(([widgetData, globalFilter, localFilter]: any) => {
      if (widgetData) this.data = widgetData.data;
      if (globalFilter) this.globalFilters = globalFilter.filters;
      if (localFilter) this.localFilters = localFilter.filters;
    }).catch(() => {
    }).finally(() => this.refreshData());
    const widgetClone = _.cloneDeep(this.widget);
    const options = _.mergeWith({}, widgetClone.options, this.data?.options, MergeDynamicOptions);
    this.evaluateFunctions(options);
    this.chartsOptions = this.adaptScalesColors(_.merge(this.getDefaultOptions(), options));
    this.localService.theme$.subscribe((val) => {
      this.theme = val;
      this.chartsOptions = this.adaptScalesColors(_.merge(this.getDefaultOptions(), options));
      this.changeDetector.detectChanges();
    });
  }

  ngAfterViewInit() {
    if (this.carouselElement) {
      const myCarouselElement = this.carouselElement.nativeElement;
      const carousel = new bootstrap.Carousel(myCarouselElement, {
        interval: 8000,
        ride: 'carousel'
      });
    }
    if (this.element) {
      Chart.defaults.font.size = Math.min(Math.max(12, Math.round(this.element.nativeElement.width / 70)), 16)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const defaultOptions: any = _.cloneDeep(this.getDefaultOptions());
    if ((changes.globalFilters || changes.staleEntities) && !changes.widget){
      this.refreshData();
    }
    if (changes.data) {
      defaultOptions.animation = {
        duration: 500,
        easing: 'easeInOutQuart',
      };
      this.isLoading = false;
      if (this.isWriteFormLoading){
        this.isWriteFormLoading = false;
        this.isInlineFormActive = false;
        this.onCloseForm();
      }
    }
    if (changes.error) {
      this.isLoading = false;
      this.isWriteFormLoading = false;
    }

    const widgetClone = _.cloneDeep(this.widget);
    const options = _.mergeWith({}, widgetClone.options, this.data?.options, MergeDynamicOptions);
    this.evaluateFunctions(options);
    this.chartsOptions = this.adaptScalesColors(_.merge(defaultOptions, options));
  }

  public onMetricWidgetClick(evt: any) {
    if (this.data?.actions) {
      this.openRecordsModal(this.data.actions['entityId'], this.data.actions['recordIds']);
    }
  }

  public onActionExecute(evt: any) {
    if (evt.action) {
      this.executingActionId = evt.action.id;
    }
    this.executeAction.emit(evt)
    this.changeDetector.detectChanges();
  }

  public onApplyFiltersSubmit(evt: any) {
    this.localFilters = evt;
    this.refreshData();
    this.onCloseForm();
    this.dbService.update('widgetsFilters', {
      id: _.toNumber(`${this.record.id}${this.widget.id}`),
      filters: evt,
    }).subscribe();
  }

  public onClearFiltersClick() {
    this.localFilters = {};
    this.activeFilters = {};
    this.refreshData();
    this.dbService.update('widgetsFilters', {
      id: _.toNumber(`${this.record.id}${this.widget.id}`),
      filters: {},
    }).subscribe();
  }

  public onWriteFormSubmit(evt: any) {
    this.isWriteFormLoading = true;
    const data = {
      'write_form_id': this.currentWriteForm?.id || this.widget.inlineWriteForm.id,
      'write_form_data': evt.data,
    }
    if (this.globalFilters.data) {
      data['dashboard_form_data'] = this.globalFilters.data;
    }
    if (this.localFilters.data) {
      data['read_form_data'] = this.localFilters.data;
    }
    const message = {
      'widget': this.widget.id,
      'record': this.record.id,
      'data': data
    }
    this.widgetWrite.emit({
      'action': 'widgetWrite',
      'message': message,
    });
  }

  public onTableWidgetHover(evt: any) {
    const cell = evt.target.closest('td');
    if (!cell) return;
    if (this.widget.inlineWriteForm || cell.dataset['actions']) {
      cell.style.cursor = 'pointer';
    }
  }

  public onTableWidgetClick(evt: any) {
    this.isWriteFormLoading = false;
    this.interactiveActions = [];
    const cell = evt.target?.closest('td');
    if (!cell) return;
    this.currentTableCell = cell;
    if (this.widget.inlineWriteForm) {
      this.interactiveActions.unshift({"title": this.widget.inlineWriteForm.title, "type": "form"});
    }
    if (cell.dataset['actions']) {
      const widgetRect = evt.target?.closest('.modal-body').getBoundingClientRect();
      const cellRect = cell.getBoundingClientRect();
      this.interactiveActionsPopoverCoordinates = {
        'left': `${cellRect.left - widgetRect.left}px`,
        'top': `${cellRect.top - widgetRect.top}px`,
        'width': `${cellRect.width}px`,
        'height': `${cellRect.height}px`
      }
      this.interactiveActions = [...JSON.parse(cell?.dataset?.actions), ...this.interactiveActions];
    }
    if (this.interactiveActions.length > 1) {
      this.popover.open();
    } else if (this.interactiveActions.length > 0) {
      const action = this.interactiveActions[0];
      if (action['type'] === "action") {
        this.popover.open();
      } else {
        this.popover.close();
        this.onTableInteractiveActionsItemClick(action);
      }
    }
  }

  public onTableInteractiveActionsItemClick(evt: any) {
    if (evt && evt['type'] == 'record') {
      this.popover.close();
      this.openRecordsModal(evt['args']['entity'], evt['args']['records']);
    }
    if (evt && evt['type'] == 'form' && this.widget.inlineWriteForm) {
      const widgetRect = this.currentTableCell.closest('.modal-body').getBoundingClientRect();
      const cellRect = this.currentTableCell.getBoundingClientRect();
      this.inlineWriteFormCoordinates = {
        'left': cellRect.left - widgetRect.left + 'px',
        'top': cellRect.top - widgetRect.top + 'px'
      }
      if (!this.isInlineFormActive) {
        this.isInlineFormActive = true;
      } else {
        this.isInlineFormActive = false;
        this.changeDetector.detectChanges();
        this.isInlineFormActive = true;
        this.changeDetector.markForCheck();
      }
    } else {

    }
  }

  public onTableInteractiveActionsPopoverOutsideClick(evt: any) {
    const cell = evt.target?.closest('td');
    if (cell && cell.dataset['actions']) return;
    if (this.modalService.hasOpenModals()) return;
    if (this.isInlineFormActive) return;
    this.popover.close();
  }

  public onTableInlineWriteFormOutsideClick(evt: any) {
    if (!this.isInlineFormActive) return;
    const popover = evt.target?.closest('.popover-body');
    const cell = evt.target?.closest('td');
    if (popover && this.popover.isOpen()) return;
    if (cell && !cell.dataset['actions']) return;
    if (this.isInlineFormActive) this.isInlineFormActive = false;
  }

  public hasActiveFilters() {
    return _.keys(this.activeFilters).length > 0;
  }

  public onCloseForm() {
    this.formModalRef.close();
  }

  public onCloseFullscreen() {
    this.fullScreenModalRef.close();
    this.isFullScreenDisplay = false;
  }

  public openModalWindow(widgetFormRef: any, fullScreen = false, writeForm = null) {
    this.currentWriteForm = writeForm;
    if (fullScreen) {
      this.fullScreenModalRef = this.modalService.open(widgetFormRef, {
        size: 'xl',
        centered: true,
        animation: true,
        fullscreen: true
      })
      this.isFullScreenDisplay = true;
      this.fullScreenModalRef.dismissed.subscribe(() => {
        this.isFullScreenDisplay = false;
        this.changeDetector.detectChanges();
      });
    } else {
      this.formModalRef = this.modalService.open(widgetFormRef, {size: 'lg'});
      if (this.panelIsFullScreen) {
        document.getElementById('pageDetail').appendChild(document.querySelector('ngb-modal-backdrop'));
        document.getElementById('pageDetail').appendChild(document.querySelector('ngb-modal-window'));
      }
    }
  }

  private getDefaultOptions() {

    const defaultOptions = {
      backgroundColor: this.colors, ...(this.widget.type === 'doughnut' && {borderColor: this.theme == 'light' ? '#fff' : '#1E1E2D'}),
      animation: this.localService.getEnv() != 'test',
      scales: {},
      hover: {
        mode: null
      },
      elements: {
        bar: {
          borderRadius: 10
        }
      },
      responsive: true,
      plugins: {
        datalabels: {
          display: false,
          font: function (context) {
            return {
              size: Math.min(Math.max(Math.round((context.chart.width / context.dataset.data.length) / 5), 11), 16)
            };
          },
        },
        legend: {
          labels: {
            font: function (context) {
              return {
                size: Math.min(Math.max(12, Math.round(context.chart.width / 65)), 16)
              };
            },
            usePointStyle: true,
          },
        },
        responsive: true,
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false,
            labels: {
              usePointStyle: true,
            },
          },
          tooltip: {
            titleFont: {
              size: 15,
              weight: 'bolder',
            },
            bodyFont: {
              size: 15,
              weight: 'bolder',
            },
            borderWidth: 0,
            boxPadding: 3,
            cornerRadius: 5,
            multiKeyBackground: '#00000000',
            boxWidth: 20,
            boxHeight: 20,
            callbacks: {
              labelColor: function (context) {
                if (context.element.options.backgroundColor.charAt(0) == '#') {
                  return {
                    backgroundColor: context.element.options.backgroundColor,
                    borderWidth: 0,
                    borderRadius: 10,
                  }
                } else {
                  return {backgroundColor: '#fff', borderWidth: 0, borderRadius: 10,}
                }
              },
            }
          },
        }
      },
      onClick: (context) => {
        const segment = context.chart.getElementsAtEventForMode(context, 'nearest', {intersect: true}, false);
        const action = this.extractActionDataFromClickedWidget(segment);
        if (action && action['entity'] && action['records']) {
          this.openRecordsModal(action.entity, action.records);
        }
      },
      onHover: (context) => {
        const segment = context.chart.getElementsAtEventForMode(context, 'nearest', {intersect: true}, false);
        const action = this.extractActionDataFromClickedWidget(segment);
        if (action && action['entity'] && action['records']) {
          context.native.target.style.cursor = "pointer";
        } else {
          context.native.target.style.cursor = "default";
        }
      }
    };

    if (this.widget.type === "radar") {
      defaultOptions.scales = {
        r: {
          angleLines: {
            color: this.computedStyle.getPropertyValue('--bs-gray-300'),
          },
        }
      }
    }
    return defaultOptions;
  }

  private evaluateFunctions(obj): void {
    var func;
    for (var k in obj) {
      if (typeof obj[k] == "object" && obj[k] !== null) {
        this.evaluateFunctions(obj[k]);
      } else if (typeof obj[k] == "string" && obj[k].startsWith("function")) {
        let val = obj[k];
        val = "func =" + val;
        // tslint:disable-next-line:no-eval
        eval(val);
        obj[k] = func;
      }
    }
  }

  private openRecordsModal(entity: number, records: number []) {
    if (records.length === 1) {
      this.widgetClick.emit({recordId: records[0], openModal: true});
    } else {
      this.widgetClick.emit({modalForMultipleRecords: true, entityId: entity, recordIds: records, openModal: true});
    }
  }

  private extractActionDataFromClickedWidget(segment): { entity: number; records: number[] } | undefined {
    if (segment.length > 0) {
      const firstPoint = segment[0];
      const datasetIndex = firstPoint.datasetIndex;
      const dataIndex = firstPoint.index;
      if (this.data?.actions?.length > 0) {
        return this.data.actions[datasetIndex][dataIndex];
      }
    }
  }

  private adaptScalesColors(options) {
    let optionsClone = _.cloneDeep(options);
    for (const [key, value] of Object.entries(options.scales)) {
      if (value["grid"]?.display != false) {
        optionsClone["scales"][key]["grid"] = {
          ...optionsClone["scales"][key]["grid"],
          color: this.computedStyle.getPropertyValue('--bs-gray-300'),
          borderColor: this.computedStyle.getPropertyValue('--bs-gray-400')
        }
      }
      optionsClone["scales"][key]["ticks"] = {
        ...optionsClone["scales"][key]["ticks"],
        color: this.computedStyle.getPropertyValue('--bs-gray-700'),
      }
    }
    return optionsClone
  }

  private showLoader() {
    this.isLoading = true;
    setTimeout(() => this.isLoading = false, 5000);
  }

  private refreshData() {
    this.showLoader();
    this.activeFilters = getActiveFilters(this.localFilters.rawData, this.localFilters.types);
    const data = {}
    if (this.globalFilters.data) {
      data['dashboard_form_data'] = this.globalFilters.data;
    }
    if (this.localFilters.data){
      data['read_form_data'] = this.localFilters.data;
    }
    const message = {
      widget: this.widget.id,
      record: this.record.id,
      data: data,
    }
    this.widgetRefresh.emit({
      'action':'widgetRead',
      'message': message,
    });
  }

}


export function MergeDynamicOptions(objValue, srcValue) {
    if (_.isObject(objValue)) {
      return _.mergeWith(objValue, srcValue, MergeDynamicOptions);
    }
    return srcValue;
  }
