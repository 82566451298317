import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  SimpleChanges,
  Renderer2
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData, NgIf, NgFor, NgClass } from '@angular/common';
import { isSameWeek, isSameDay, getISOWeek } from 'date-fns';
import { Observable, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import {Level} from '../../services/user.service';
import {Actions, ofActionSuccessful, Store} from "@ngxs/store";
import {HomePage, RecordsPage} from "src/app/state/app.actions";
import { translate, TranslocoService, TranslocoDirective } from "@jsverse/transloco";
import Calendar from '@event-calendar/core';
import Event from '@event-calendar/core';
import TimeGrid from '@event-calendar/time-grid';
import DayGrid from '@event-calendar/day-grid';
import Interaction from '@event-calendar/interaction';
import ResourceTimeline from '@event-calendar/resource-timeline';
import List from '@event-calendar/list';
import { CreateComponent } from '../create/create.component';
import { NgStringPipesModule, NgObjectPipesModule } from 'ngx-pipes';
import { CheckDirective } from '../../directives/check.directive';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from '../loader/loader.component';
import { EmptyComponent } from '../empty/empty.component';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { getGroupByField, getGroupByValue, getRecordsGroups } from '../../app.utils';
import { LocalService } from '../../services/local.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

registerLocaleData(localeFr);

@Component({
    selector: 'calendar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [TranslocoDirective, NgIf, DragAndDropModule, EmptyComponent, NgFor, LoaderComponent, NgClass, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, CreateComponent, CheckDirective, NgStringPipesModule, NgObjectPipesModule]
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() records;
  @Input() unscheduledRecords;
  _unscheduledCount;
  @Input('unscheduledCount')
  set in(count) {
    if (count !== null) this._unscheduledCount = count;
  }
  @Input() isUnscheduledReady;
  @Input() isCreatingRecord;
  @Input() entity;
  @Input() scope;
  @Input() showUnscheduled;
  @Input() isListReady;
  @Input() groupBy = false;
  @Input() collapsedGroups = {};
  @Input() createModalDismissTrigger$: Observable<void>;
  @Input() initTrigger$: Observable<void>;
  @Input() timeframe;

  _listMode;
  @Input()
  set listMode(_listMode) {
    this._listMode = _listMode?.toLowerCase() === 'true';
  }

  get listMode() {
    return this._listMode;
  }

  @Output() calendarScopeDateChange = new EventEmitter();
  @Output() calendarUnscheduledChange = new EventEmitter();
  @Output() calendarRecordClick = new EventEmitter();
  @Output() calendarRecordDrag = new EventEmitter();
  @Output() calendarUnscheduledNext = new EventEmitter();
  @Output() createEvent = new EventEmitter();
  @Output() resizeEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();

  @ViewChild('unscheduledContainer') unscheduledContainer: ElementRef;
  @ViewChild('calendar') calendarRef: ElementRef;
  @ViewChild('create', {static: false}) create: CreateComponent;
  @ViewChild('createContainer') createContainerRef: ElementRef;
  @ViewChild('changeScopeDropdown') changeScopeDropdownRef: ElementRef;
  @ViewChild('collapseAllBtn') collapseAllBtnRef: ElementRef;

  readonly #takeUntilDestroyed = takeUntilDestroyed();
  calendar: Calendar;
  events: Event[] = [];
  viewTypes = {
    month: 'dayGridMonth',
    week: 'timeGridWeek',
    day: 'timeGridDay',
    monthGroupBy : 'resourceTimelineMonth',
    weekGroupBy: 'resourceTimelineWeek',
    dayGroupBy: 'resourceTimelineDay',
    monthList: 'listMonth',
    weekList: 'listWeek',
    dayList: 'listDay'
  };
  scopes = {
    'day': translate("Jour"),
    'week': translate('Semaine'),
    'month': translate('Mois')
  }
  hideWeekends = false;
  isSelecting: any = {
    state: false,
    selectedRange: null
  };
  groups = [];
  scopeDate: Date;
  refresh: Subject<any> = new Subject();
  recordsSub: Subscription;
  homeSub: Subscription;
  switchMode: Subscription;
  unscheduled = false;
  unscheduledLoaded = false;
  unscheduledEvents: any[] = [];
  page = 1;
  firstLoad = false;
  isLoading = false;
  canScroll = true;
  unscheduledEventBound = false;
  locale = this.translocoService.getActiveLang();
  computedStyle = getComputedStyle(document.body);

  get allCollapsed(): boolean {
    return _.every(this.groups, (group) => _.get(group, 'extendedProps.collapsed') === true);
  }

  readonly DEFAULT_SCOPE = 'month';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private actions$: Actions,
    private translocoService :TranslocoService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private localService: LocalService,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.init();
    this.createModalDismissTrigger$?.pipe(this.#takeUntilDestroyed).subscribe(() => this.create.CreateModal.dismiss());
    this.initTrigger$?.pipe(this.#takeUntilDestroyed).subscribe(() => this.init());
  }

  init(){
    this.unscheduled = this.showUnscheduled === 'true' ? true : this.unscheduled;
    this.scope = this.scope || this.getSavedScope() || this.DEFAULT_SCOPE;
    if (!_.has(this.scopes, this.scope)) this.scope = this.DEFAULT_SCOPE;
    this.scopeDate = new Date(this.getTimeFrame());
    if (this.unscheduled) this.calendarUnscheduledNext.emit();
    this.initFirstLoad();
    this.initEvents();
  }

  ngAfterViewInit(): void {
    this.initCalendar();
    this.onCurrentDateChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isListReady?.currentValue || this.isListReady) {
      this.isLoading = false;
    }
    this.unscheduledRecords = this.unscheduledRecords || [];
    if (changes.scope) {
      if (!changes.scope.currentValue) return;
      this.changeScope(this.scope);
    }
    if (changes.records) {
      this.initEvents();
      this.calendar?.unselect();
      if (this.groupBy) {
        this.initGroupBy();
      }
      this.calendar?.refetchEvents();
      this.refreshCalendarButtons();
    }
    if (changes.groupBy) {
      this.groupBy ? this.switchToGroupByView() : this.clearGroupBy();
    }
    if (changes.showUnscheduled) {
      this.unscheduled = this.showUnscheduled === 'true';
    }
    if (changes.unscheduledRecords) {
      this.initUnscheduledEvents();
      this.refreshCalendarButtons();
    }
  }

  ngOnDestroy(): void {
    this.homeSub.unsubscribe();
    this.recordsSub.unsubscribe();
    this.switchMode.unsubscribe();
  }

  initCalendar(): void {
    const calendarRef = this.calendarRef.nativeElement;
    const changeScopeDropdown = this.changeScopeDropdownRef.nativeElement;

    this.calendar = new Calendar({
      target: calendarRef,
      props: {
        plugins: [TimeGrid, DayGrid, Interaction, ResourceTimeline, List],
        options: this.getCalendarOptions()
      }
    });
    const calendarBtnGroup = this.calendarRef.nativeElement.querySelector('.ec-end');
    this.renderer.appendChild(calendarBtnGroup, changeScopeDropdown);
  }

  initEventsTooltips(): void {
    const calendarViewPort = this.calendarRef.nativeElement.querySelector('.ec-body');
    const viewPortRect = calendarViewPort.getBoundingClientRect();
    const events = this.calendarRef.nativeElement.querySelectorAll('article.ec-event.ec-draggable');

    const handleEventsScroll = _.debounce(() => {
      _.forEach(events, (event) => {
        const eventTitle = event.querySelector('.ec-event-title');
        const tooltip = event.nextElementSibling;
        if (!eventTitle || !tooltip) return;

        const eventTitleRect = eventTitle.getBoundingClientRect();
        const isOverflowing = eventTitleRect.right < viewPortRect.left;

        eventTitle.style.visibility = isOverflowing ? 'hidden' : 'visible';
        tooltip.classList.toggle('visible', isOverflowing);
      });
    }, 10);

    calendarViewPort.addEventListener('scroll', () => {
      requestAnimationFrame(handleEventsScroll);
    });
  }

  getCalendarOptions(): any {
    return {
      view: this.getCurrentView(),
      date: this.getTimeFrame(),
      viewDidMount: info => this.updateCurrentScope(info.type),
      height: '80vh',
      selectable: this.getSelectable(),
      unselectCancel: '.no-unselect, .modal-dialog',
      selectBackgroundColor: 'rgba(0, 0, 0, 0.2)',
      dayMaxEvents: true,
      nowIndicator: true,
      pointer: true,
      firstDay: 1,
      eventSources: [{ events: () => this.events }],
      eventClick: info => this.onEventClick(info.event),
      eventResize: info => this.onEventDateChange(info.event),
      eventDrop: info => this.onEventDrop(info),
      eventDidMount: info => this.onEventMount(info),
      eventAllUpdated: info => this.onEventsUpdate(),
      select: info => this.onSelect(info.start, info.end, info.resource),
      dayHeaderFormat: info => this.getDayHeaderFormat(info),
      listDayFormat: info => this.getListDayFormat(info),
      listDaySideFormat: info => this.getListDaySideFormat(info),
      titleFormat: (start, end) => this.getTitleFormat(start, end),
      dayPopoverFormat: info => this.getDayPopoverFormat(info),
      noEventsContent: () => this.getNoEventsContent(),
      allDayContent: () => this.getAllDayContent(),
      resourceLabelContent: (info) =>  this.setGroupsLabelsContent(info),
      customButtons: this.getCustomButtons(),
      buttonText: this.getButtonsTextTranslations(),
      headerToolbar: this.getHeaderToolbar(),
      datesSet: info => this.handleDatesSet(info.view.type),
      moreLinkContent: info => this.getMoreLinkTranslations(info),
      theme: theme => this.getCalendarTheme(theme)
    };
  }

  getSelectable() {
    return this.createEvent.observers.length > 0;
  }

  refreshCalendarButtons(): void {
    if (this.calendar) {
      this.calendar.setOption('customButtons', this.getCustomButtons());
      this.calendar.setOption('headerToolbar', this.getHeaderToolbar());
      const listModeBtn = this.calendarRef.nativeElement.querySelector('.ec-listMode');
      listModeBtn.disabled = this.groupBy;
    }
  }

  getCustomButtons(): any {
    return {
      unscheduledEvents: {
        text: translate("records non planifiés", {
          unscheduledCount: this._unscheduledCount,
          e: this.entity?.isMasculine ? '' : 'e',
          s: this._unscheduledCount !== 1 ? 's' : ''
        }),
        click: () => this.switchUnscheduled(),
        active: this.unscheduled
      },
      // hideWeekends: {
      //   text: translate("Masquer les week-ends"),
      //   click: () => this.toggleWeekends()
      // },
      listMode: {
        text: translate("Vue en liste"),
        click: () => this.toggleListMode(),
        active: this.listMode
      }
    };
  }

  getHeaderToolbar(): any {
    const unscheduledBtn = this._unscheduledCount ? 'unscheduledEvents' : '';

    return {
      start: 'prev,today,next',
      center: 'title',
      end: `${unscheduledBtn} listMode`
    };
  }

  getNoEventsContent(): any {
    return translate("Aucun record à afficher", {
      e: this.entity?.isMasculine ? '' : 'e',
      title: this.entity.title
    });
  }

  getAllDayContent() {
    if (this.scope == 'week') return translate('Toute la semaine')
    return translate('Toute la journée');
  }

  getDateFormatted(info, formatOptions) {
    const langMap = {
      'fr': 'fr-FR',
      'en': 'en-EN',
      'cn': 'zh',
    };
    const currentLang = langMap[this.localService.getLanguage()];
    return info.toLocaleDateString(currentLang, formatOptions);
  }

  getCurrentScope() {
    if (this.calendar) {
      const currentView = this.calendar.getOption('view');
      return _.findKey(this.viewTypes, value => value === currentView)?.replace(/GroupBy$/, '')?.replace(/List$/, '');
    } else {
      const calendarConfig = JSON.parse(this.route.snapshot.queryParams?.calendarConfig || '{}');
      return _.has(this.scopes, calendarConfig.scope) ? calendarConfig.scope : this.scope;
    }
  }

  getTitleFormat(start, end) {
    const currentScope = this.getCurrentScope();
    const dayFormatMap = {
      'month': { year: 'numeric', month: 'long' },
      'week': { day: 'numeric', month: 'short', year: 'numeric' },
      'day': { year: 'numeric', month: 'long', day: 'numeric' },
    };
    const dayFormat = dayFormatMap[currentScope] || null;
    const weekNumber = getISOWeek(end);

    return currentScope === 'week'
      ? `${this.getDateFormatted(start, dayFormat)} - ${this.getDateFormatted(end, dayFormat)} (${translate('Semaine').substring(0,1)}${weekNumber})`
      : this.getDateFormatted(start, dayFormat);
  }

  getDayPopoverFormat(info) {
    return this.getDateFormatted(info, { day: 'numeric', month: 'long', year: 'numeric' });
  }

  getDayHeaderFormat(info) {
    const currentScope = this.getCurrentScope();
    const dayFormatMap = {
      'month': this.groupBy ? { weekday: 'short', day: 'numeric' } : { weekday: 'short' },
      'week': { weekday: 'short', day: 'numeric' },
      'day': { weekday: 'long' },
    };

    const dayFormat = dayFormatMap[currentScope] || null;
    return this.getDateFormatted(info, dayFormat);
  }

  getListDayFormat(info) {
    return this.getDateFormatted(info, { weekday: 'long' });
  }

  getListDaySideFormat(info) {
    return this.getDateFormatted(info, { day: 'numeric', month: 'long', year: 'numeric' });
  }

  getCalendarTheme(theme): any {
    return {
      ...theme,
      button: 'btn btn-sm btn-secondary fs-6 text-gray-700',
      active: 'active btn-info',
      title: 'text-gray-800 me-auto ms-4 flex-center text-center',
      toolbar: 'ec-toolbar d-flex justify-content-between align-items-center py-3 px-2'
    };
  }

  getButtonsTextTranslations(): any{
    return {
      dayGridMonth: translate('Mois'),
      timeGridDay: translate('Jour'),
      timeGridWeek: translate('Semaine'),
      today: translate("Aujourd'hui"),
      resourceTimelineMonth: translate('Mois'),
      resourceTimelineDay: translate('Jour'),
      resourceTimelineWeek: translate('Semaine'),
      listMonth: translate('Mois'),
      listDay: translate('Jour'),
      listWeek: translate('Semaine')
    }
  }

  getMoreLinkTranslations(info): string {
    return translate("events de plus", {count: info.num});
  }

  handleDatesSet(viewType: string): void {
    if (viewType !== this.getCurrentView()) {
      this.updateCurrentScope(viewType);
    }
    this.onCurrentDateChanged();
  }

  restoreGroupsCollapseState(groups) {
    return _.map(groups, (group) => {
      const collapsed = this.collapsedGroups[group.id] ?? false;
      return { ...group, extendedProps: { collapsed } }
    });
  }

  getCalendarResources(): any[] {
    if (!this.groupBy) return [];
    const groupByFieldName = this.route.snapshot.queryParams?.groupby;
    const groups = getRecordsGroups(this.entity, this.records, groupByFieldName);
    return this.restoreGroupsCollapseState(groups);
  }

  setGroupsLabelsContent(info): any {
    if (!this.calendar || info.resource.id == 'undefined') return { domNodes: [] };

    const groupId = +info.resource.id || 'null';
    const resource = _.find(this.groups, { id: groupId });
    const eventsCount = _.get(_.groupBy(this.events, 'resourceId'), groupId).length;
    const { title, picture } = resource;

    const collapseButton = this.renderer.createElement('button');
    this.renderer.setAttribute(collapseButton, 'id', `collapse-btn-${resource.id}`);
    this.renderer.addClass(collapseButton, 'ec-nesting-groups');
    this.renderer.addClass(collapseButton, 'btn');
    this.renderer.addClass(collapseButton, 'btn-sm');

    const isCollapsed = resource.extendedProps.collapsed;

    this.renderer.addClass(collapseButton,isCollapsed ? 'collapsed' : 'expanded');
    this.renderer.listen(collapseButton, 'click', () => {
      const ecResource = collapseButton.closest('.ec-resource');
      this.toggleCollapseGroup(ecResource, resource.id)
    });

    let pictureCircle = null;
    if (picture) {
      pictureCircle = this.renderer.createElement('div');
      this.renderer.addClass(pictureCircle, 'symbol');
      this.renderer.addClass(pictureCircle, 'symbol-30px');
      this.renderer.addClass(pictureCircle, 'symbol-circle');
      this.renderer.addClass(pictureCircle, 'me-2');
      this.renderer.setAttribute(pictureCircle, 'title', title);

      const img = this.renderer.createElement('img');
      this.renderer.setAttribute(img, 'alt', 'avatar');
      this.renderer.setAttribute(img, 'src', picture);
      this.renderer.addClass(img, 'img-fit');

      this.renderer.appendChild(pictureCircle, img);
    }

    const titleSpan = this.renderer.createElement('span');
    this.renderer.addClass(titleSpan, 'text-gray-800');
    this.renderer.addClass(titleSpan, 'fs-5');
    this.renderer.addClass(titleSpan, 'fw-bold');
    this.renderer.addClass(titleSpan, 'pt-0');
    const titleText = this.renderer.createText(title);
    this.renderer.appendChild(titleSpan, titleText);

    const countSpan = this.renderer.createElement('span');
    this.renderer.addClass(countSpan, 'badge');
    this.renderer.addClass(countSpan, 'badge-light-info');
    this.renderer.addClass(countSpan, 'rounded');
    this.renderer.addClass(countSpan, 'fs-5');
    this.renderer.addClass(countSpan, 'ms-3');
    this.renderer.addClass(countSpan, 'px-3');
    this.renderer.addClass(countSpan, 'px-b');
    const countText = this.renderer.createText(eventsCount.toString());
    this.renderer.appendChild(countSpan, countText);


    const containerDiv = this.renderer.createElement('div');
    this.renderer.addClass(containerDiv, 'd-flex');
    this.renderer.addClass(containerDiv, 'align-items-center');

    this.renderer.appendChild(containerDiv, collapseButton);
    if (pictureCircle) {
      this.renderer.appendChild(containerDiv, pictureCircle);
    }
    this.renderer.appendChild(containerDiv, titleSpan);
    this.renderer.appendChild(containerDiv, countSpan);
    return {
      domNodes: [containerDiv]
    };
  }

  toggleCollapseGroup(groupElement, groupId, isCollapsed?): void {
    const ecContent = groupElement.closest('.ec-content');
    const resourceIndex = _.indexOf([...ecContent.children], groupElement);
    const ecDaysContainer = this.calendarRef.nativeElement.querySelector('.ec-main .ec-body .ec-content');
    const resourceRecordsContainer = _.nth(
      _.filter([...ecDaysContainer.children], div => div.classList.contains('ec-days')),
      resourceIndex
    );

    const groupIndex = _.findIndex(this.groups, { id: groupId });
    if (groupIndex === -1) return;

    const group = this.groups[groupIndex];
    isCollapsed = isCollapsed ?? !group.extendedProps.collapsed;
    group.extendedProps.collapsed = isCollapsed;
    this.collapsedGroups[groupId] = isCollapsed;

    this.groups[groupIndex] = { ...group, extendedProps: { ...group.extendedProps } };

    const currentState = isCollapsed ? 'collapsed' : 'expanded';
    const oppositeState = isCollapsed ? 'expanded' : 'collapsed';

    this.renderer.removeClass(groupElement, oppositeState);
    this.renderer.addClass(groupElement, currentState);

    this.renderer.removeClass(resourceRecordsContainer, oppositeState);
    this.renderer.addClass(resourceRecordsContainer, currentState);

    this.refreshGroupsDisplay();
  }

  toggleCollapseAll(): void {
    const collapseAll = !this.allCollapsed;
    _.forEach(this.groups, (group) => {
      const groupCollapseBtn = this.calendarRef.nativeElement.querySelector(`#collapse-btn-${group.id}`);
      const groupElement = groupCollapseBtn.closest('.ec-resource');
      this.toggleCollapseGroup(groupElement, group.id, collapseAll);
    })
  }

  refreshGroupsDisplay(): void {
    this.calendar.setOption('eventSources', [{ events: () => this.events }]);
    this.calendar.refetchEvents();
    this.cdr.detectChanges();
  }

  getEventGroup(record): any[] {
    if (!this.groupBy) return [];
    const groupByFieldName = this.route.snapshot.queryParams?.groupby;
    const groupByField = getGroupByField(this.entity, groupByFieldName);
    const groupByValue = getGroupByValue(record, groupByField).fieldValue;

    return this.groups.find(resource => resource.title == groupByValue).id;
  }

  initGroupBy(): void {
    if (!this.calendar) return;
    this.setupEventsGrouping();
    this.setupGroupsFolding();
  }

  setupEventsGrouping(): void {
    if (this.groupBy && this.calendar) {
      this.groups = this.getCalendarResources();
      this.calendar.setOption('resources', this.groups);
      this.refreshGroupsDisplay();
      this.groupEvents();
    }
  }

  setupGroupsFolding(): void {
    const resourcesHeader = this.calendarRef.nativeElement.querySelector('.ec-sidebar-title');
    if (!resourcesHeader || !this.collapseAllBtnRef) return;
    this.renderer.appendChild(resourcesHeader, this.collapseAllBtnRef.nativeElement);
  }

  switchToGroupByView(): void {
    if (this.listMode) this.toggleListMode();
    this.calendar?.setOption('view', this.viewTypes[this.scope + 'GroupBy']);
  }

  clearGroupBy(): void {
    this.calendar?.setOption('view', this.viewTypes[this.scope]);
  }

  recordsAsEvents(): Event[] {
    return _.reduce(this.records, (records, r) => {
      if (r.value[this.entity.startingDateField.id] && r.value[this.entity.endingDateField.id]) {
        records.push(this.recordToEvent(r));
      }
      return records;
    }, []);
  }

  recordToEvent(record): Event {
    const { color, id, accessLevel, value, extendedProps = {} } = record;

    const title = record.title || record.str;
    const editable = accessLevel > Level.Write;
    const start = value[this.entity.startingDateField.id] ? new Date(value[this.entity.startingDateField.id]) : null;
    const end = value[this.entity.endingDateField.id] ? new Date(value[this.entity.endingDateField.id]) : null;
    const isUnscheduled = !start || !end;
    const allDay = (this.entity.startingDateField.type === 'Date' && this.entity.endingDateField.type === 'Date') ||
      (!isUnscheduled && (
        this.scope === 'month' ||
        (this.scope === 'week' && !isSameWeek(start, end, { weekStartsOn: 1 })) ||
        (this.scope === 'day' && !isSameDay(start, end))
      ));
    const backgroundColor = color
      ? this.computedStyle.getPropertyValue(`--bs-${color}`)
      : this.computedStyle.getPropertyValue('--bs-primary');

    if (!extendedProps.record) {
      extendedProps['record'] = record;
    }

    return {
      id,
      allDay,
      start,
      end,
      title,
      editable,
      backgroundColor,
      extendedProps: {
        record: {
          ...extendedProps.record,
          color
        },
        isUnscheduled
      }
    };
  }

  groupEvents(): Event[] {
    if(!this.groupBy) return this.events;
    this.events = _.map(this.events, (event: Event) => {
      const resourceId = this.getEventGroup(event.extendedProps.record);
      return {
        ...event,
        resourceId: resourceId
      }
    });

  }

  initEvents() {
    this.events = this.recordsAsEvents();
    this.refresh.next(null);
  }

  onCurrentDateChanged() {
    if (!this.calendar) return;

    this.isLoading = true
    const { currentStart, currentEnd } = this.calendar.getView();
    this.timeframe = moment(currentStart).format("YYYY-MM-DD");
    this.calendarScopeDateChange.emit({
      scope: this.scope,
      currentStart: currentStart,
      currentEnd: currentEnd,
    });
  }

  getCurrentView(): string {
    if (this.groupBy) return this.viewTypes[this.scope + 'GroupBy'];
    if (this.listMode) return this.viewTypes[this.scope + 'List']
    return this.viewTypes[this.scope];
  }

  getTimeFrame(): string {
    const todayTimeFrame = moment().format('YYYY-MM-DD');
    return this.timeframe ?? todayTimeFrame;
  }

  getSavedScope(): string {
    const calendarConfigRaw = this.store.selectSnapshot(state => state.app.recordsPage.user.saves.records?.[this.entity.id]?.calendarConfig);

    if (calendarConfigRaw) {
      const calendarConfig = JSON.parse(calendarConfigRaw);
      return calendarConfig?.scope;
    }

    return this.DEFAULT_SCOPE;
  }

  updateCurrentScope(viewType: string): void {
    this.scope = _.findKey(this.viewTypes, value => value === viewType);
    if (this.groupBy) this.scope = this.scope.replace(/GroupBy$/, '');
    if (this.listMode) this.scope = this.scope.replace(/List$/, '');
  }

  onEventClick(event: Event): void {
    this.calendarRecordClick.emit({
      recordId:event.extendedProps.record.id,
      openModal: this.entity.isDetailPageInModal
    });
  }

  onEventMount(info): void {
    if (info.event.id == '{pointer}') return;
    const startDate = info.event.start.toLocaleDateString();
    const endDate = new Date(info.event.end.getTime() - 60000).toLocaleDateString();
    info.el.setAttribute('title', `${startDate} - ${endDate}`);
  }

  clearTooltips(): void {
    const tooltipElements = this.calendarRef.nativeElement.querySelectorAll('.ec-event-tooltip');
    _.forEach(tooltipElements, tooltipEl => {
      tooltipEl.remove();
    })
  }

  onEventsUpdate(): void {
    this.clearTooltips();
    if (!this.groupBy) return;
    this.initEventsTooltips();
    const eventElements = this.calendarRef.nativeElement.querySelectorAll('article.ec-event.ec-draggable');
    for (const event of eventElements) {
      this.mountEventTooltip(event);
    }
  }

  mountEventTooltip(eventElement: HTMLElement): void {
    const calendarContentRect = this.calendarRef.nativeElement.querySelector('.ec-body .ec-content').getBoundingClientRect();
    const eventRect = eventElement.getBoundingClientRect();
    const tooltipWidth = calendarContentRect.right - eventRect.right;
    if (tooltipWidth < 0) return;

    const { marginTop, backgroundColor } = getComputedStyle(eventElement);
    const eventWidth = eventElement.offsetWidth;
    const tooltip = this.renderer.createElement('div');
    this.renderer.addClass(tooltip, 'ec-event');
    this.renderer.addClass(tooltip, 'ec-event-tooltip');
    this.renderer.setStyle(tooltip, 'margin-top', marginTop);
    this.renderer.setStyle(tooltip, 'left', `${eventWidth}px`);
    this.renderer.setStyle(tooltip, 'width', `${tooltipWidth}px`);

    const tooltipTitle = this.renderer.createElement('h4');
    const arrowTooltip = this.renderer.createElement('span');
    this.renderer.addClass(tooltipTitle, 'ec-event-title');
    this.renderer.addClass(arrowTooltip, 'ec-tooltip-arrow');
    this.renderer.setStyle(tooltipTitle, 'background-color', backgroundColor);
    this.renderer.setStyle(arrowTooltip, 'border-right', `10px solid ${backgroundColor}`);

    const eventTitle = eventElement.querySelector('.ec-event-title') as HTMLElement;
    this.renderer.appendChild(tooltipTitle, this.renderer.createText(eventTitle.innerText));
    this.renderer.appendChild(tooltip, arrowTooltip);
    this.renderer.appendChild(tooltip, tooltipTitle);

    this.renderer.insertBefore(eventElement.parentElement, tooltip, eventElement.nextSibling);
  }

  onEventDrop(info: any): void {
    const { delta, event, oldResource, newResource } = info;
    if (oldResource && newResource && (oldResource !== newResource)) {
      const groupByFieldName = this.route.snapshot.queryParams?.groupby;
      const groupByField = getGroupByField(this.entity, groupByFieldName);
      this.updateEvent.emit({
        id: +event.id,
        value : {
          [groupByField.id]: +info.newResource.id
        }
      });
    }
    if (delta.seconds !== 0) {
      this.onEventDateChange(info.event);
    }
  }

  onEventDateChange(event: Event): void {
    const updatedEvent = {
      record: {
        id: event.extendedProps.record.id,
      },
      start: event.start,
      end: event.end
    }

    this.resizeEvent.emit(updatedEvent)
  }

  calendarDrop(event) {
    let groupByField = null;
    let groupByValue = null;
    if (this.groupBy) {
      const resource = this.calendar.dateFromPoint(event.clientX, event.clientY).resource;
      const firstRecordInGroup = _.find(this.records, { id: _.find(this.events, {resourceId: +resource.id}).extendedProps.record.id });
      const groupByFieldName = this.route.snapshot.queryParams?.groupby;
      groupByField = getGroupByField(this.entity, groupByFieldName);
      groupByValue = getGroupByValue(firstRecordInGroup, groupByField);
    }

    const targetDate = this.calendar.dateFromPoint(event.clientX, event.clientY);
    const startDate = targetDate.date;
    const endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
    const droppedEvent = event.dropData.event;

    this.calendarRecordDrag.emit({
      newDate: {
        start: startDate,
        end: endDate
      },
      groupByField: groupByField?.id,
      groupByValue: groupByValue?.id,
      record: droppedEvent.extendedProps.record
    });

    const unscheduledIndex = this.unscheduledEvents.indexOf(droppedEvent);
    if (unscheduledIndex > -1) {
      this.loadNextIfMissing();
      this.unscheduledEvents.splice(unscheduledIndex, 1);
      this.events.push(droppedEvent);
    }
    this.refresh.next(null);
  }

  toggleButton(element: Element, isActive: boolean) {
    const switchOnClass = isActive ? ['active', 'btn-info'] : ['btn-secondary'];
    const switchOffClass = isActive ? ['btn-secondary'] : ['active', 'btn-info'];
    _.forEach(switchOnClass, (className: string) => element.classList.add(className));
    _.forEach(switchOffClass, (className: string) => element.classList.remove(className));
  }

  switchUnscheduled() {
    if (this._unscheduledCount > 0) {
      const unscheduledBtn = this.calendarRef.nativeElement.querySelector('.ec-unscheduledEvents');
      this.unscheduled = !this.unscheduled;
      this.toggleButton(unscheduledBtn, this.unscheduled);

      this.calendarUnscheduledChange.emit(this.unscheduled);
      if (this.unscheduled && !this.unscheduledLoaded) {
        this.unscheduledLoaded = true;
      }
    }
  }

  toggleWeekends(): void {
    const hideWeekendsBtn = this.calendarRef.nativeElement.querySelector('.ec-hideWeekends');
    this.hideWeekends = !this.hideWeekends;
    this.toggleButton(hideWeekendsBtn, this.hideWeekends);

    const hiddenDays = this.hideWeekends  ? [0, 6] : [];
    this.calendar.setOption('hiddenDays', hiddenDays);
  }

  toggleListMode(): void {
    if (this.groupBy) this.clearGroupBy();

    const listModeBtn = this.calendarRef.nativeElement.querySelector('.ec-listMode');
    this._listMode = !this.listMode;
    this.updateCalendarConfigQp('list', this.listMode);
    this.refreshCalendarButtons();
    this.toggleButton(listModeBtn, this.listMode);

    if (this.listMode) {
      this.calendar?.setOption('view', this.viewTypes[this.scope + 'List'])
    } else {
      this.calendar?.setOption('view', this.viewTypes[this.scope]);
    }
  }

  onSelect(startDate: Date, endDate: Date, resource?: any): void {
    this.isSelecting = {
      state: true,
      selectedRange: {
        start: startDate,
        end: new Date(endDate.getTime() - 60000)
      },
      resource,
    }
    this.cdr.detectChanges();
    if (this.entity.creationForm) {
      this.create.openCreationModal(this.create.creationFormRef);
      this.calendar.unselect();
    } else if (this.entity.mainField) {
      const previewEvent = this.calendarRef.nativeElement.querySelector('.ec-event.ec-preview');
      this.renderer.insertBefore(previewEvent, this.createContainerRef.nativeElement, previewEvent.firstChild);
    } else {
      this.handleCreateSubmit();
    }
  }

  handleCreateSubmit(eventData?: any) {
    const { mainField, startingDateField, endingDateField, creationForm } = this.entity;
    const { resource, selectedRange } = this.isSelecting;
    const resourceId = _.isNil(resource?.id) ? 'null' : Number(resource?.id);

    let fieldsValues = {
      ...(creationForm ? { form_data: eventData } : { [mainField?.id]: eventData }),
      ...(startingDateField ? { [startingDateField.id]: selectedRange.start } : {}),
      ...(endingDateField ? { [endingDateField.id]: selectedRange.end } : {}),
    };

    if (this.groupBy && !_.isNaN(resourceId)) {
      const firstRecordInGroup = _.find(this.records, {
        id: _.find(this.events, { resourceId })?.extendedProps?.record?.id,
      });

      if (firstRecordInGroup) {
        const groupByFieldName = this.route.snapshot.queryParams?.groupby;
        const groupByField = getGroupByField(this.entity, groupByFieldName);
        const groupByValue = getGroupByValue(firstRecordInGroup, groupByField);

        fieldsValues = {
          ...fieldsValues,
          [groupByField?.id]: groupByValue?.id,
        };
      }
    }
    if (this.createEvent.emit({ fieldsValues }) !== undefined) this.createDismiss();
  }

  createDismiss() {
    this.isSelecting = {
      state: false,
      selectedRange: null
    }
  }


  loadNextIfMissing() {
    const unscheduledContent = document.querySelector<HTMLElement>('.unscheduled-content');
    if (unscheduledContent.scrollHeight - 50 <= unscheduledContent.offsetHeight) {
      this.onScroll();
    }
  }

  onScroll() {
    if (this._unscheduledCount > this.unscheduledRecords.length) {
      this.calendarUnscheduledNext.emit();
    }
  }

  initFirstLoad() {
    this.recordsSub = this.actions$.pipe(ofActionSuccessful(RecordsPage.ChangeCalendarDates)).subscribe((res) => {
      if (!this.firstLoad && this.store.snapshot().app.recordsPage.isListReady) {
        this.firstLoad = true;
      }
    });
    this.homeSub = this.actions$.pipe(ofActionSuccessful(HomePage.ChangeCalendarDates)).subscribe((res) => {
      if (!this.firstLoad && this.store.snapshot().app.homePage.isListReady) {
        this.firstLoad = true;
      }
    });
    this.switchMode = this.route.queryParams.subscribe((queryParams) => {
      if (queryParams["mode"] != "calendar") {
        this.firstLoad = false;
      }
    })
  }

  eventTimesChanged({
                      event,
                      newStart,
                      newEnd,
                    }: any): void {
    this.calendarRecordDrag.emit({newDate: newStart, record: event.extendedProps.record});
    const unscheduledIndex = this.unscheduledEvents.indexOf(event);
    if (unscheduledIndex > -1) {
      this.loadNextIfMissing();
      this.unscheduledEvents.splice(unscheduledIndex, 1);
      this.events.push(event);
    }
    event.start = newStart;
    event.end = newEnd;
    this.refresh.next(null);
  }

  changeScope(scope: any) {
    this.isLoading = true;
    this.scope = scope;
    this.calendar?.setOption('view', this.getCurrentView());
  }

  initUnscheduledEvents() {
    if (!this.unscheduledContainer?.nativeElement){
      this.unscheduledEventBound = false;
    }
    this.unscheduledEvents = [];
    for (let record of this.unscheduledRecords) {
      this.unscheduledEvents.push(this.recordToEvent(record));
    }
    this.canScroll = true;
    if (this.unscheduledContainer?.nativeElement && !this.unscheduledEventBound){
      this.unscheduledEventBound = true;
      let unscheduledElement: HTMLElement = this.unscheduledContainer.nativeElement
      let callback = () => {
        if (this.canScroll && unscheduledElement.clientHeight >= unscheduledElement.scrollHeight - unscheduledElement.scrollTop){
          this.canScroll = false;
          this.onScroll()
        }
      }
      unscheduledElement.removeAllListeners();
      unscheduledElement.addEventListener('scroll', callback)
    }
  }

  externalDrop(event: any) {
    if (this.unscheduledEvents.indexOf(event) === -1) {
      this.calendarRecordDrag.emit({newDate: null, record: event.extendedProps.record});
      this.events = this.events.filter((iEvent) => iEvent !== event);
      this.unscheduledEvents.push(event);
    }
  }

  getCalendarConfig(): { [key: string]: any } {
    const config = this.route.snapshot.queryParams?.calendarConfig;
    return config ? JSON.parse(config) : {};
  }

  updateQueryParams(params: { [key: string]: any }): void {
    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  updateCalendarConfigQp(name: string, values: any): void {
    const calendarConfig = this.getCalendarConfig();
    calendarConfig[name] = values;

    this.updateQueryParams({
      calendarConfig: JSON.stringify(calendarConfig),
    });
  }
}
