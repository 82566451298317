import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'splitStr',
    standalone: true
})
export class SplitStringPipe implements PipeTransform {

  transform(value: string, [separator]): any {
    return value.split(separator);
  }

}
