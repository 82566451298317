import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnChanges,
  OnDestroy, HostListener
} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import { AppState } from 'src/app/state/app.state';
import {HomePage} from "src/app/state/app.actions";
import {ActivatedRoute, Router} from "@angular/router";
import {RecordService} from '../../services/record.service';
import { combineLatest, Subject } from 'rxjs';
import {applyApplicationStyle, deepCompare, openDetailPage} from 'src/app/app.utils';
import { NgbModal, NgbAccordionDirective, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import {filter, takeUntil} from 'rxjs/operators';
import { WebSocketService } from 'src/app/services/websocket.service';
import * as _ from "lodash";
import {LocalService} from "../../services/local.service";
import { LoaderComponent } from '../../components/loader/loader.component';
import { EmptyComponent } from '../../components/empty/empty.component';
import { TrackDirective } from '../../directives/track.directive';
import { IconComponent } from '../../components/icon/icon.component';
import { UploadComponent } from '../../components/upload/upload.component';
import { CalendarComponent } from '../../components/calendar/calendar.component';
import { TimelineComponent } from '../../components/timeline/timeline.component';
import { PivotTableComponent } from '../../components/pivot-table/pivot-table.component';
import { GridComponent } from '../../components/grid/grid.component';
import { CardsComponent } from '../../components/cards/cards.component';
import { NgIf, NgTemplateOutlet, NgFor, AsyncPipe, TitleCasePipe, KeyValuePipe } from '@angular/common';
import { DefaultComponent } from '../../components/default/default.component';
import { TranslocoDirective } from '@jsverse/transloco';
declare var $: any;

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, DefaultComponent, NgIf, NgTemplateOutlet, CardsComponent, GridComponent, PivotTableComponent, TimelineComponent, CalendarComponent, UploadComponent, NgbAccordionDirective, NgFor, NgbAccordionItem, NgbAccordionHeader, NgbAccordionToggle, NgbAccordionButton, IconComponent, NgbCollapse, NgbAccordionCollapse, NgbAccordionBody, TrackDirective, EmptyComponent, LoaderComponent, AsyncPipe, TitleCasePipe, KeyValuePipe]
})

export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select(AppState.homePage) homePage$;
  @ViewChild('DetailPageModal') detailPageModal;
  @HostListener('window:resize')
  public onResize() {
    this.changeDetector.detectChanges();
  }

  jsonParse = JSON.parse;
  page = 1;
  window = window;
  scrollDisabled = false;
  allElementsLoaded = false;
  queryParams;
  callInit = true;
  destroyed$: Subject<any> = new Subject();
  loadedAtLeastOnce = false;
  loading = false;
  activeViews = [];
  calendarInitTrigger$ = new Subject<void>();

  constructor(public store: Store,
              private router: Router,
              public changeDetector: ChangeDetectorRef,
              private recordService: RecordService,
              private userService: UserService,
              public localService: LocalService,
              private route: ActivatedRoute,
              private webSocketService: WebSocketService) {}

  ngOnInit() {
    this.scrollDisabled = true;
    this.webSocketService.messages.pipe(filter(data => (data !==  null && data.message.type === 'highlight')),
      takeUntil(this.destroyed$)).subscribe((data) => {
      const content = data.message.data;
      if (content.actor && content.actor !== this.userService.localService.getUser() &&
        content.entity && content.entity === this.store.snapshot().app.homePage?.entity?.id){
        this.store.dispatch(new HomePage.Init(this.queryParams, true));
      }
    });
    this.webSocketService.messages.pipe(filter(data => (data !==  null && ['edition', 'action'].includes(data.message.type))),
      takeUntil(this.destroyed$)).subscribe((data) => {
      const message = data.message;
      if (data.message.user !== this.userService.localService.getUser() && data.message.type === 'edition'){
        if (message.sender.id && message.method === 'DELETE' && message.sender.entity === this.store.snapshot().app.homePage.entity?.id){
          this.store.dispatch(new HomePage.Init(this.queryParams, true));
        }
      } else {
        if (message.sender.entity === this.store.snapshot().app.homePage.entity?.id && this.store.snapshot().app.homePage.currentView.value.mode !== 'timeline'){
          this.store.dispatch(new HomePage.Init(this.queryParams, true));
        }
      }
    });



    combineLatest([this.route.queryParams]).pipe(takeUntil(this.destroyed$)).subscribe(([queryParams]) => {
      // prevent calling init while we're selecting another view
      if (!this.callInit) return;
      const queryParamsClone = _.cloneDeep(this.queryParams);
      if (this.queryParams && queryParamsClone["timeframe"]) {
        delete queryParamsClone["timeframe"];
      }
      if (this.queryParams && queryParamsClone["dashboardConfig"]) {
        delete queryParamsClone["dashboardConfig"];
      }
      const snapshotQueryParamsClone = _.cloneDeep(this.route.snapshot.queryParams);
      if (snapshotQueryParamsClone["timeframe"]) {
        delete snapshotQueryParamsClone["timeframe"];
      }
      if (snapshotQueryParamsClone["dashboardConfig"]) {
        this.queryParams = this.route.snapshot.queryParams;
        delete snapshotQueryParamsClone["dashboardConfig"];
      }
      const queryParamsDiff = deepCompare(queryParamsClone, snapshotQueryParamsClone);
      if (!_.isEqual(queryParamsClone, snapshotQueryParamsClone) && !queryParamsDiff.includes("timelineConfig")) {
        this.queryParams = queryParams;
        this.store.dispatch(new HomePage.Init(queryParams)).subscribe(res => {
          this.loadedAtLeastOnce = true;
          if (this.store.snapshot().app.homePage.currentView){
            this.allElementsLoaded = this.store.snapshot().app.homePage.currentView.count <= 18;
          }
          if (res.app.homePage.latestResult !== 0) {
            this.scrollDisabled = false;
          }
          this.activeViews = _.keys(this.store.snapshot().app.homePage.views);
          this.changeDetector.detectChanges();
        });
      }
    });
  }


  ngAfterViewInit(){
    this.homePage$.subscribe(state =>{
      var style = this.store.snapshot().app?.sider?.user?.space?.style;
      if (style && state.isReady){
        applyApplicationStyle(style)
      }
    })
  }


  openDetailPage(evt) {
    openDetailPage(evt);
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  showRecords(view) {

    if (this.store.snapshot().app.homePage.currentView && view.id == this.store.snapshot().app.homePage.currentView.id) return
    this.callInit = false;
    // reset queryParams
    this.router.navigate([], {
      queryParams: {},
    });

    this.scrollDisabled = false;
    this.page = 1;
    this.store.dispatch(new HomePage.RetrieveRecords(view, {}, this.page)).subscribe((res) => {
      this.allElementsLoaded = res.app.homePage.records[this.store.snapshot().app.homePage.currentView.id].filter((el) => el.id > -1).length == this.store.snapshot().app.homePage.currentView.count
      this.changeDetector.detectChanges();
      this.scrollDisabled = false;
      // if (view.value.dashboardConfig !== null && this.dashboard) this.dashboard.ngOnInit();
      // if (view.value.calendarConfig !== null && this.calendar) this.calendar.ngOnInit();
    }, () => {}, () => {
      this.callInit = true;
      if (view.value.calendarConfig !== null) {
        this.changeDetector.detectChanges();
        this.calendarInitTrigger$.next()
      }
    });
    this.closeSidebar();
  }

  onUploadSelect(file) {
    this.store.dispatch(new HomePage.UploadPicture(file));
  }

  onCardsScroll() {
    this.scrollDisabled = true;
    this.store.dispatch(new HomePage.RetrieveRecords(this.store.snapshot().app.homePage.currentView, this.queryParams, this.page + 1)).subscribe(res => {
      this.page++;
      setTimeout(() => {
        this.allElementsLoaded = res.app.homePage.records[this.store.snapshot().app.homePage.currentView.id].filter((el) => el.id > -1).length == this.store.snapshot().app.homePage.currentView.count
      },0);
      if (res.app.homePage.latestResult !== 0 && !this.allElementsLoaded) {
        this.scrollDisabled = false;
        this.changeDetector.detectChanges();
      }
    });
  };

  onScrollGrid(){
    this.loading = true;
    this.store.dispatch(new HomePage.RetrieveRecords(this.store.snapshot().app.homePage.currentView, this.queryParams, this.page + 1))
    .pipe(takeUntil(this.destroyed$))
    .subscribe(res => {
      setTimeout(() => {
        this.allElementsLoaded = res.app.homePage.records[this.store.snapshot().app.homePage.currentView.id].filter((el) => el.id > -1).length == this.store.snapshot().app.homePage.currentView.count
      },0);
      this.page++;
      this.loading = false;
      this.changeDetector.detectChanges();
    });
  }

  onCalendarScopeDateChanged(evt) {
    const { currentStart, currentEnd } = evt;
    this.store.dispatch(new HomePage.ChangeCalendarDates(currentStart, currentEnd, this.queryParams));
    this.changeDetector.detectChanges();
  }

  onRecordDrag(data) {
    this.store.dispatch(new HomePage.RecordDrag(data));
  }

  onUnscheduledNext() {
    this.store.dispatch(new HomePage.RetrieveUnscheduledRecords(this.store.snapshot().app.homePage.currentView, this.page));
    this.page++;
  }

  onGanttDateChange(data) {
    const entity = this.store.snapshot().app.homePage.entity;
    this.recordService.retrieveObject(data.record).subscribe((r: any) => {
      this.store.dispatch(new HomePage.UpdateRecordFields(r, {
          [entity.startingDateField.id]: data.start,
          [entity.endingDateField.id]: data.end
        }
      )).subscribe((res) => {
      }, (e) => {
        if (e.status === 400) {
          this.showRecords(this.store.snapshot().app.homePage.currentView);
        }
      });
    });
  }

  onGanttScroll() {
    this.loading = true;
    this.store.dispatch(new HomePage.RetrieveRecords(this.store.snapshot().app.homePage.currentView, this.queryParams, this.page + 1)).subscribe(res => {
      this.page++;
      this.loading = false;
    });
  }

  closeSidebar(){
    if (this.localService.isMobile()) {
      $('.dismiss-offcanvas').click();
    }
  }
}
