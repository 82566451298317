import {Pipe, PipeTransform} from '@angular/core';
import { translate } from '@jsverse/transloco';

@Pipe({
    name: 'formatCheckbox',
    standalone: true
})
export class FormatCheckboxPipe implements PipeTransform {

  transform(value: Array<any>|string): any {
    if (value) {
       if (typeof value === 'string') {
        return value
      }
      return value.map((v) => {
        switch(v) {
          case true:
          case 'true':
            return translate('Oui')
          case false:
          case 'false':
            return translate('Non')
          default:
            return v
        }
      })
    }
  }
}
