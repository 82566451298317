import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'groupReactions',
  standalone: true
})
export class GroupReactionsPipe implements PipeTransform {

  transform(reactions: { user: { id: number; fullname: string }; emoji: string }[]): { emoji: string; count: number; users: { id: number; fullname: string }[] }[] {
    const groupedReactions = _.reduce(reactions, (result: Record<string, { count: number; users: { id: number; fullname: string }[] }>, reaction) => {
      const emoji = reaction.emoji;
      const user = { id: reaction.user.id, fullname: reaction.user.fullname };

      if (!result[emoji]) {
        result[emoji] = { count: 0, users: [] };
      }
      result[emoji].count++;
      result[emoji].users.push(user);

      return result;
    }, {});

    return _.map(groupedReactions, (value, key) => ({
      emoji: key,
      count: value.count,
      users: value.users
    }));
  }
}
