import { NgModule } from '@angular/core';
import { AuthenticationGuard } from './guards/authentication.guard';
import { HomeComponent } from './pages/home/home.component';
import { RecordComponent } from './pages/record/record.component';
import { RecordsComponent } from './pages/records/records.component';
import { AdministrationComponent } from './pages/administration/administration.component';
import { ModalComponent } from './pages/modal/modal.component';
import {Routes, RouterModule, Router, ActivatedRouteSnapshot} from '@angular/router';
import * as _ from 'lodash';
import { SsoGatewayComponent } from './pages/sso-gateway/sso-gateway.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
  {path: 'login', loadComponent: () => import('./pages/login/login.component').then(c => c.LoginComponent)},
  {path: 'reset', loadComponent: () => import('./pages/reset/reset.component').then(c => c.ResetComponent)},
  {path: 'sendreset', loadComponent: () => import('./pages/sendreset/sendreset.component').then(c => c.SendresetComponent)},
  {path: 'records/:id', component: RecordsComponent, canActivate: [AuthenticationGuard]},
  {path: 'record/:id', component: RecordComponent, canActivate: [AuthenticationGuard]},
  {path: 'modal/records/:id', outlet: 'modal', component: ModalComponent, canActivate: [AuthenticationGuard]},
  {path: 'modal/record/:id', outlet: 'modal', component: ModalComponent, canActivate: [AuthenticationGuard]},
  {path: 'record/:id/:tab', component: RecordComponent, canActivate: [AuthenticationGuard]},
  {path: 'modal/record/:id/:tab', outlet: 'modal', component: ModalComponent, canActivate: [AuthenticationGuard]},
  {
    path: 'notifications', 
    loadComponent: () => import('./pages/notifications/notifications.component').then(c => c.NotificationsComponent), 
    canActivate: [AuthenticationGuard]
  },
  {path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard]},
  {path: '404', component: NotFoundComponent},
  {path: 'administration/:entityId/:model', component: AdministrationComponent, canActivate: [AuthenticationGuard]},
  {
    path: 'preferences', 
    loadComponent: () => import('./pages/preferences/preferences.component').then(c => c.PreferencesComponent), 
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'releases', 
    loadComponent: () => import('./pages/releases/releases.component').then(c => c.ReleasesComponent), 
    canActivate: [AuthenticationGuard]
  },
  {path: ':ssoGatewayName', component: SsoGatewayComponent},
  {path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'disabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = (future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot) => {
      if (!future.routeConfig && !current.routeConfig) {
        return true;
      }
      return (future.routeConfig.path === current.routeConfig.path && (_.isEqual(future.params, current.params) || (
        future.routeConfig.path.split('/')[0] === 'record' && _.isEqual(future.params.id, current.params.id)
      )));
    };
  }
}
