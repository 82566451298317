import {Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';
import {Sider} from "../../state/app.actions";
import { Store } from '@ngxs/store';
import { SpecialTitleCasePipe } from '../../pipes/special-title.pipe';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../icon/icon.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, NgClass, IconComponent, RouterLink, SpecialTitleCasePipe]
})
export class BreadcrumbComponent implements OnInit {

  @Input() entity;
  @Input() isReady;
  @Input() count;
  @Input() currentCount;
  @Input() user;




  constructor(
      public store: Store,
  ) { }

  ngOnInit(): void {
  }
  switchSpace(spaceId) {
    this.store.dispatch(new Sider.SwitchSpace(spaceId,true));
  }

}
