import { Pipe, PipeTransform } from '@angular/core';
import {DateFormatPipe} from "ngx-moment";
import * as moment from "moment";
import parseFormat from 'moment-parseformat';
import {translate} from "@jsverse/transloco";

@Pipe({
    name: 'datetimeFormat',
    standalone: true
})
export class FormatDateTimePipe implements PipeTransform {

  transform(value: Date | moment.Moment | string | number, ...args: any[]): string {
    if (!value) return '';
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    const preferredOrder = locale === 'en-US' ? 'MDY' : 'DMY';
    const format = parseFormat(value, { preferredOrder });
    return moment(value, format).format(args[0] || 'lll');
  }
}
