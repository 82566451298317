import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'flattenBlocks',
    standalone: true
})
export class FlattenBlocksPipe implements PipeTransform {

  transform(grouped_blocks: any) {
    let flat_blocks = [];
    for (const blockTitle in grouped_blocks) {
      const parentBlock = grouped_blocks[blockTitle];
      if (blockTitle !== "undefined") {
        if (parentBlock["0"].block) {
          for (const index in parentBlock["0"].block) {
            parentBlock[index] = parentBlock["0"].block[index];
          }
          for (const index in parentBlock) {
            if (parentBlock[index] && parentBlock[index].hasOwnProperty('id')){
              const childBlock = parentBlock[index];
              parentBlock[index] = childBlock;
            }
          }
          flat_blocks.push(parentBlock);
        }
      } else {
        for (const i in parentBlock) {
          if ((parentBlock[i]['title'] in grouped_blocks)) {
            let b = flat_blocks.find(block => block['id'] === grouped_blocks[block][i]['id']);
            b['widgets'] = parentBlock[i]['widgets'];
          } else {
            flat_blocks.push(parentBlock[i]);
          }
        }
      }
    }
    return flat_blocks;
  }
}
