import { Component, OnInit } from '@angular/core';
import { Error404Component } from '../../components/error404/error404.component';
import { DefaultComponent } from '../../components/default/default.component';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: true,
    imports: [TranslocoDirective, DefaultComponent, Error404Component]
})
export class NotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
