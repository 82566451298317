import { Pipe, PipeTransform } from '@angular/core';
import {markdownToElement} from "../app.utils";

@Pipe({
    name: 'markdownToText',
    standalone: true
})
export class MarkdownToTextPipe implements PipeTransform {

  transform(input: string) {
    if (!input) {
      return '';
    }
    const wrapper = markdownToElement(input);
    return wrapper.innerText;
  }
}
