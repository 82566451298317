import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { NgIf } from '@angular/common';
declare var Gauge: any;

@Component({
    selector: 'gauge',
    templateUrl: './gauge.component.html',
    styleUrls: ['./gauge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf]
})
export class GaugeComponent implements OnInit, AfterViewInit {

  @Input() options: any;
  @Input() data: any

  @ViewChild('gauge') gauge: ElementRef;
  @ViewChild('element') element: ElementRef;

  public defaultOptions: object = {
    value: 0,
    minValue: 0,
    maxValue: 100,
    lines: 500,
    angle: -0.1,
    lineWidth: 0.2,
    pointer: {
      length: 0.5,
      strokeWidth: 0.035,
      color: '#4f4f4f'
    },
    limitMax: 'true',
    percentColors: [[0.0, '#B8D935'], [0.50, '#E8C444'], [1.0, '#F06445']],
    strokeColor: '#E0E0E0',
    generateGradient: false,
    animationSpeed: 200,
    labelSize: '18px',
    borderRadius: 5
  };

  constructor() {
  }

  ngOnInit(): void {
    this.defaultOptions = {...this.defaultOptions, ...this.options, ...this.data};
  }

  ngAfterViewInit(): void {
    const gauge = new Gauge(this.gauge.nativeElement).setOptions(this.defaultOptions);
    gauge.minValue = this.defaultOptions['minValue'];
    gauge.maxValue = this.defaultOptions['maxValue'];
    gauge.animationSpeed = this.defaultOptions['animationSpeed'];
    gauge.set(this.defaultOptions['value']);
  }
}
