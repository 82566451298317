import { Inject, Injectable, Injector } from "@angular/core";
import { BaseService } from "./base.service";
import { LocalService } from "./local.service";
import { webSocket, WebSocketSubject } from "rxjs/webSocket"
import {ActivatedRoute} from '@angular/router';
import { BehaviorSubject ,Subject} from "rxjs";
import { Cache } from "../classes/cache";
import { Store } from "@ngxs/store";
import {RecordService} from "./record.service";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class WebSocketService extends BaseService {

  public messages: Subject<any> = new Subject()

  private userWebSocket: WebSocketSubject<any>;
  private retryTimeout;

  constructor(private store: Store,
              public injector: Injector,
              private route: ActivatedRoute,
              private localService: LocalService,
              @Inject("Cache") private cache: Cache){
    super(injector);
    window.onbeforeunload = () => {
      this.disconnect();
    };
  }

  connect() {
    if (!this.localService.getUser()) return;

    const WS_ENDPOINT = this.envService.wsEndpoint + 'users/' + this.localService.getUser() + '/';

    clearTimeout(this.retryTimeout);
    this.userWebSocket = webSocket({
        url: WS_ENDPOINT,
      }
    );

    this.userWebSocket.subscribe((res) => {
      const type = res.message.type;
      if (type === 'action') {
        this.cache.invalidateFromStales(res.message.response);
        this.injector.get(RecordService).retrieveObject(res.message.sender).toPromise().then((record) => {
          this.cache.invalidateFromEdition(record, 'POST', 'edition');
          res.message.sender = record;
          this.messages.next(res);
        });
      } else {
        if (type === 'notification') {
          this.cache.invalidateFromNotifications();
        } else if (type === 'highlight') {
          this.cache.invalidateFromHighlights(res.message.data, this.localService.getUser());
        }  else if (type === 'sync') {
          this.cache.invalidateFromStales(res.message.response);
        } else if (type === 'edition') {
          this.cache.invalidateFromStales(res.message.response);
          res.message.sender = JSON.parse(res.message.sender);
          let recordId;
          this.route.paramMap.subscribe(params => {
            recordId = params.get('id');
          });
          const sameUserAndRecord = res.message.user === this.localService.getUser()
            && res.message.sender.id === this.store.selectSnapshot((state) => state.app.recordPage.getRecord(recordId)).record?.id
            && res.message.type === 'edition';
          if (!sameUserAndRecord){
            this.cache.invalidateFromEdition(res.message.sender, res.message.method, res.message.type);
          }
        }
        this.messages.next(res);
      }
    },
    () => {
      this.retryTimeout = setTimeout(() => {
        this.connect();
      }, 10000) ;
    },
    () => {
      console.log("WS connection closed");
    });
  }

  disconnect(){
    this.userWebSocket?.complete();
  }

  sendMessage(action: string, data: any): void {
    const message = {'action': action, ...data};
    this.userWebSocket.next(message);
  }

}
