import { Component, Output, Input, EventEmitter, ElementRef, ViewChild, OnInit, OnChanges } from '@angular/core';
import { NgFor, DecimalPipe } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
@Component({
    selector: 'evaluation-color',
    templateUrl: './evaluation-color.component.html',
    styleUrls: ['./evaluation-color.component.scss'],
    standalone: true,
    imports: [TranslocoDirective, NgFor, DecimalPipe]
})
export class EvaluationColorComponent implements OnInit, OnChanges {
  private _noteMin;
  private _noteMax;
  @Output() public checkpointNotesUpdate = new EventEmitter();
  @ViewChild('slider', { static: true }) slider!: ElementRef<HTMLDivElement>;
  @Input() record;
  @Input() extension;
  @Input() checkpoint;
  @Input()
  set noteMin(value: number | null) {
    this._noteMin = value;
  }
  @Input()
  set noteMax(value :number | null) {
    this._noteMax = value;
  }
  get noteMin(): number {
    return this._noteMin !== null ? this._noteMin : 0;
  }
  get noteMax(): number {
    return this._noteMax !== null && this._noteMax !== 0 ? this._noteMax : 100;
  }

  step = 0.1;
  ranges: { min: number, max: number, state: string, color: string }[] = [];
  rangesSliderLimits: { min: number, max: number, state: string, color: string }[] = [];

  public checkpointStates = [{
    value: 'Accepted',
    color: 'lime',
  }
  // , {
  //   value: 'Fixed',
  //   color: 'info',
  // }
  , {
    value: 'PartiallyConform',
    color: 'carrot',
  }
  , {
    value: 'Rejected',
    color: 'danger',
  }];

  constructor() {
  }

  ngOnInit(){
    if (this.checkpoint.notes?.ranges) {
      this.ranges = this.checkpoint.notes?.ranges;
    }

    this.calculateSliderLimits();
  }

  ngOnChanges(changes) {
    if (changes['checkpoint'] && this.checkpoint?.notes?.ranges) {
      this.ranges = this.checkpoint.notes.ranges;
    }

    this.calculateSliderLimits();
    const slider = this.slider.nativeElement as HTMLDivElement;
    const sliderWidth = slider.clientWidth;
  }

  onHandleMouseDown(event: MouseEvent, index: number, isStartHandle: boolean): void {
    event.preventDefault();

    const onMouseMove = (moveEvent: MouseEvent) => {
        const slider = this.slider.nativeElement as HTMLDivElement;
        const sliderWidth = slider.clientWidth;
        const clickX = moveEvent.pageX - slider.getBoundingClientRect().left;
        let newValue = 0;
        if (clickX <= 0){
          newValue = this.noteMin;
        } else if (clickX > sliderWidth){
          newValue = this.noteMax;
        } else {
          newValue = Math.round((clickX / sliderWidth) * (this.noteMax - this.noteMin) / this.step) * this.step + this.noteMin
        }
        if (isStartHandle) {
            if (newValue >= this.noteMin && newValue <= this.rangesSliderLimits[index].max - this.step) {
                this.rangesSliderLimits = this.rangesSliderLimits.map((range, i) =>
                    i === index ? { ...range, min: newValue } : range
                );
            }
        } else {
            if (newValue <= this.noteMax && newValue >= this.rangesSliderLimits[index].min + this.step) {
                this.rangesSliderLimits = this.rangesSliderLimits.map((range, i) =>
                    i === index ? { ...range, max: newValue } : range
                );
            }
        }
    };

    const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  isRangeConflict(): boolean {
    for (let i = 0; i < this.rangesSliderLimits.length; i++) {
        const newRange = this.rangesSliderLimits[i];
        const hasConflict = this.rangesSliderLimits.some((range, index) =>
            index !== i && !(newRange.max < range.min || newRange.min > range.max)
        );
        if (hasConflict) {
            return true;
        }
    }
    return false;
}


  toggleActiveCheckpointStates(state,color) {
    const index = this.getIndexByState(state);
    let startValue;
    if (this.rangesSliderLimits.length > 0 ) {
      startValue = this.noteMin + ((this.noteMax - this.noteMin) / 4 * (this.rangesSliderLimits.length) + 0.1);
    } else {
      startValue =  this.noteMin;
    }
    let endValue = this.noteMin +  ((this.noteMax - this.noteMin) / 4 * (this.rangesSliderLimits.length + 1));
    if (startValue >= endValue) {
      startValue = startValue - this.step;
      endValue = startValue + this.step;
    }
    if (index === -1) {
      this.rangesSliderLimits = [...this.rangesSliderLimits, { min: startValue, max: endValue, state: state, color: color }];
    } else {
      this.rangesSliderLimits = this.rangesSliderLimits.filter((_, i) => i !== index);

    }
  }

  getIndexByState(state: string): number {
    return this.rangesSliderLimits.findIndex(range => range.state === state);
  }

  calculateSliderLimits() {
    this.rangesSliderLimits = [];
    let r : { min: number, max: number, state: string, color: string };
    for (let i = 0; i < this.ranges.length; i++) {
      let min = Math.max(this.noteMin, this.ranges[i].min);
      let max = Math.min(this.noteMax, this.ranges[i].max);
      if (min < max) {
        r = {
          min: min,
          max: max,
          color: this.ranges[i].color,
          state: this.ranges[i].state,
        }
      this.rangesSliderLimits.push(r);
      }
    }
  }
}
