import {Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';
import { NgIf, NgFor } from '@angular/common';
import { GoogleMap, MapMarker } from '@angular/google-maps';

@Component({
    selector: 'maps',
    templateUrl: './maps.component.html',
    styleUrls: ['./maps.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [GoogleMap, NgIf, MapMarker, NgFor]
})
export class MapsComponent implements OnInit {

  @Input() locations;
  @Input() location;
  @Input() zoom;
  @Input() height;
  @Input() width;

  mapOptions: {};

  constructor() { }

  ngOnInit(): void {
    this.mapOptions = {
      draggable: this.locations ? true : false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false
    }
  }

  mapReady(event) {
    if(this.locations) {
      let bounds = new google.maps.LatLngBounds();
      let map = event;

      this.locations.forEach(element => {
        let elem = new google.maps.LatLng(element['lat'], element['lng'])
        bounds.extend(elem)
      });

      map.fitBounds(bounds)
    }
  }

}
