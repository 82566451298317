import {OnInit, OnDestroy, AfterViewInit, Component, ViewChild, HostListener} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngxs/store';
import {ModalProxyService} from "../../services/modal-proxy.service";
import {RecordPage, RecordsPage} from '../../state/app.actions';
import { RecordsComponent } from '../records/records.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RecordComponent } from '../record/record.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [NgIf, RecordComponent, InfiniteScrollModule, RecordsComponent]
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('modal') modal;
  @ViewChild('recordsComp') recordsComp: RecordsComponent | undefined;
  private modalRef: NgbModalRef;
  recordId;
  entityId: number | undefined;
  modalForMultipleRecords: boolean = false;
  modalWindow: any;

  constructor(private route: ActivatedRoute,
              private ngbModal: NgbModal,
              private router: Router,
              private store: Store,
              private modalService: ModalProxyService) {}

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.openModal();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.modalService.popModalOutletBackup()
    this.modalService.popQueryParamsBackup()
  }

  openModal(){
    let tab ;
    let modalWindow;
    this.modalForMultipleRecords = this.router.url.includes('modal/records');

    this.route.paramMap.subscribe(params => {
      if(this.modalForMultipleRecords){
        this.entityId = parseInt(params.get('id'));
      }else{
        this.recordId = params.get('id');
        tab = params.get('tab');
      }
    });

    const modalId = this.modalForMultipleRecords ? `modal-${this.entityId}` : `modal-${this.recordId}`;
    modalWindow = document.getElementById(modalId);
    
    if (!modalWindow){
      let backdropClass;
      if (document.getElementsByTagName('ngb-modal-backdrop')[0]){
        backdropClass = 'd-none';
      }
      this.modalRef = this.ngbModal.open(this.modal, {
        windowClass: this.modalForMultipleRecords ? 'records-page-modal' :'record-page-modal',
        size: 'xl',
        scrollable: true,
        backdropClass: backdropClass
      });
      this.handleModalDismissed();
    } else {
      this.initiateModal(tab);
    }
  }

  handleModalDismissed(){
    this.modalRef.dismissed.subscribe((reason) => {
      if(!reason || reason !== "popstate"){
        this.navigateBackOnModalDismissed();
      }
    });
  }

  navigateBackOnModalDismissed(){
    const modalOutletBackup = this.modalService.popModalOutletBackup();
    this.router.navigate(['.', {outlets: modalOutletBackup}], {
      relativeTo: this.route.parent,
      queryParams: this.modalService.popQueryParamsBackup()
    });
  }

  initiateModal(tab?: any){
    if(this.modalForMultipleRecords){
      const querParamas = {...this.route.snapshot.queryParams}
      this.store.dispatch(new RecordsPage.Init({'entity': this.entityId, ...querParamas}, false)).pipe().subscribe((res: any) => {});
    }else{
      this.store.dispatch(new RecordPage.Init(this.recordId, tab)).pipe().subscribe((res: any) => {});
    }
  }

  onModalCardsScroll() {
    if(!this.recordsComp.store.snapshot().app.recordsPage.allElementsLoaded){
      this.recordsComp.onCardsScroll();
    }
  }

  onCancel(reason) {
    this.modalRef.dismiss(reason);
  }

  ngOnDestroy(){
    this.modalService.onDestroyModal();
  }
}
