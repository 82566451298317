import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, signal, computed, input} from '@angular/core';
import * as _ from 'lodash';
import { NgArrayPipesModule } from 'ngx-pipes';
import { TrackDirective } from '../../directives/track.directive';
import { FilterComponent } from '../filter/filter.component';
import { NgFor, NgIf } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { blocksToFlattenFields } from 'src/app/app.utils';

@Component({
    selector: 'filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, FilterComponent, TrackDirective, NgArrayPipesModule]
})
export class FiltersComponent implements OnInit {
  @Input() entity;
  mode = input<any>();
  @Input() params

  @Output() filterUpdate = new EventEmitter<any>();
  fields = signal([]);
  spaceFilters = signal([]);
  fieldFilters = computed(() => this.fields().filter(f => 
                                              !this.isExcludedOnCalendarMode(f) 
                                              && !this.isExcludedOnTimelineMode(f)
                                              && f.type != 'Spaces' && f.type != 'Space')
                                            );                                    
  hasAuditFilters = signal(false);
  hasTagFilters= signal(false);
  hasSharedSpaceFilters = signal(false);

  constructor() {      
  }

  ngOnInit(): void {
    this.fields.set(blocksToFlattenFields(this.entity.blocks).filter(f => f.hasFilter));
    this.spaceFilters.set(this.fields().filter(f => (f.type === 'Space' || f.type === 'Space')));
    this.hasAuditFilters.set(this.checkAuditFilters());
    this.hasTagFilters.set(this.checkTagFilters());
    this.hasSharedSpaceFilters.set(this.checkSharedSpaceFilters());
  }

  getValues(field) {
    let result = [];
    if (this.params[field.name]) {
      const paramsClone = _.cloneDeep(this.params[field.name]);
      result = Array.isArray(paramsClone) ? paramsClone : [paramsClone];
    }
    return result;
  }

  onFilterChange(field, values){
    this.filterUpdate.emit({field,values})
  }

  isExcludedOnCalendarMode(field){
    const isEntityEndDate = field.id === this.entity.endingDateField?.id;
    return this.mode() === 'calendar' && isEntityEndDate
  }

  isExcludedOnTimelineMode(field){
    const isEntityEndDate = field.id === this.entity.endingDateField?.id;
    const isEntityStartDate = field.id === this.entity.startingDateField?.id;
    return this.mode() === 'timeline' && (isEntityStartDate || isEntityEndDate);
  }

  checkAuditFilters(): boolean{
    return this.entity.extensions.filter(e => e.type === 'Audit').length && this.entity.checklistsEntity;
  }

  checkTagFilters(): boolean{
    return this.entity.extensions.filter(e => e.type === 'Tags').length && this.entity.tags.length;
  }

  checkSharedSpaceFilters(): boolean{
    return this.entity.sharedSpaces.length || this.fields().filter(f => (f.type === 'Space')).length;
  }
}
