import { Pipe, PipeTransform } from '@angular/core';
import {DateFormatPipe} from "ngx-moment";
import * as moment from "moment";
import parseFormat from 'moment-parseformat';
import {translate} from "@jsverse/transloco";

@Pipe({
    name: 'dateFormat',
    standalone: true
})
export class FormatDatePipe extends DateFormatPipe implements PipeTransform {

  transform(value: Date | moment.Moment | string | number, ...args: [string?]): string {
    if (!value) return '';
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    const preferredOrder = locale === 'en-US' ? 'MDY' : 'DMY';
    const format = parseFormat(value, { preferredOrder });
    const dateMoment = moment(value, format);
    const currentYear = new Date().getFullYear();
    const defaultFormat = dateMoment.year() === currentYear ? 'DD MMM' : 'DD MMM YYYY';
    return dateMoment.format(args[0] || defaultFormat);
  }
}
