import { Injectable } from '@angular/core';
import {State, Action, Store, ofAction, Actions, Selector, createSelector} from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of, throwError} from 'rxjs';
import {tap, mergeMap, catchError, takeUntil, delay} from 'rxjs/operators';
import * as _ from 'lodash';

import {RecordsPage, HomePage, Sider, RecordPage} from './app.actions';
import { LocalService } from '../services/local.service';
import { UserService } from '../services/user.service';
import { EntityService } from '../services/entity.service';
import { RecordService } from '../services/record.service';
import { ViewService } from '../services/view.service';
import {translate } from '@jsverse/transloco';
import {mutateState, getMode, groupRecords} from '../app.utils';
import { ActionService } from '../services/action.service';
import { RecordPageState } from './record-page.state';


@State({
  name: 'recordsPage',
  defaults: {
    mode: '',
    entity: null,
    views: [],
    hiddenViews: [],
    user: null,
    records: [],
    count: null,
    scheduledCount: null,
    isListReady: true,
    unscheduledRecords: [],
    unscheduledCount: null,
    isUnscheduledReady: true,
    currentPage: 1,
    calendarStart: null,
    calendarEnd: null,
    isCreating: {
      type: 'record',
      value: false
    },
    latestView: null,
    lastId: null,
    allElementsLoaded: false
  }
})
@Injectable()
export class RecordsPageState {

  constructor(private localService: LocalService,
              private userService: UserService,
              private entityService: EntityService,
              private recordService: RecordService,
              private actionService: ActionService,
              private viewService: ViewService,
              private store: Store,
              private toastr: ToastrService,
              private actions$: Actions,
             ) {
  }

  @Action(RecordsPage.CreateRecord)
  createRecord(ctx, { fieldsValues, indexGroupBy = null }) {
    mutateState(ctx, draft => {
      draft.isCreating = {
        type: 'record',
        value: true
      };
    })
    let entity = ctx.getState().entity;
    let newRecord = {};
    newRecord['entity'] = entity.id;
    newRecord['space'] = this.localService.getSpace();
    newRecord['value'] = {};
    let mainField = entity.mainField;
    if (entity.creationForm) {
      newRecord['form_data'] = fieldsValues;
    } else if (mainField) {
      newRecord['value'][mainField.id] = fieldsValues[mainField.id];
    }
    if (['calendar', 'timeline'].includes(ctx.getState().mode)) {
        _.forEach(fieldsValues, (value, key) => {
        if (key == 'form_data'){
          newRecord['form_data'] = value;
        }
        else if(key != "space") {
            newRecord['value'][key] = value;
        } else {
            newRecord['space'] = value;
        }
    });
    }

    return this.recordService.createObject(newRecord).pipe(
      tap((record: any) => {
        mutateState(ctx, draft => {
          if (ctx.getState().mode == 'timeline') {
            _.forEach(fieldsValues, (value, key) => {
                record['value'][key] = value;
            });
            draft.records.splice(indexGroupBy != null ? indexGroupBy+1 : 0, 0, record);
            draft.count++;
            draft.total++;
            draft.scheduledCount++;
          }
          draft.lastId = record.id;
          draft.isCreating = {
            type: 'record',
            value: false
          };
        });
      }),
      catchError(err => {
        mutateState(ctx, draft => {
          draft.isCreating = {
            type: 'record',
            value: false
          };
        });
        return throwError(err);
      })
    );
  }

  @Action(RecordsPage.CreateView)
  createView(ctx, {view}) {
    view.user = this.localService.getUser();
    view.entity = ctx.getState().entity.id;
    return this.viewService.createObject(view, ['users']).pipe(tap((res: any) => {
      mutateState(ctx, draft => {
        draft.views.push(res);
        draft.latestView = res;
      });
    }));
  }

  @Action(RecordsPage.DestroyView)
  destroyView(ctx, { viewId }) {
    return this.viewService.destroyObject(viewId).pipe(tap((res: any) => {
      this.store.dispatch(new HomePage.ClearView(viewId));
      mutateState(ctx, draft => {
        draft.views = draft.views.filter(v => v.id !== viewId);
        draft.latestView = draft.views[0];
      });
    }));
  }

  @Action(RecordsPage.UpdateView)
  UpdateView(ctx, { viewId, viewData }) {
    mutateState(ctx, draft => {
      draft.views = draft.views.map(view => {
        if (view.id === viewId) {
          _.forEach(viewData, (val, key) => {
            view[key] = val;
          })
        }
        return view;
      });
    });
    return this.viewService.updateObject({id: viewId, ...viewData}, ['users']).pipe(tap((updatedView: any) => {
      mutateState(ctx, draft => {
        draft.views = draft.views.map(view => view.id === updatedView.id ? updatedView : view);
        if (draft.latestView.id == updatedView.id) {
          draft.latestView = updatedView;
        }
      });
    }));
  }

  @Action(RecordsPage.Init, {cancelUncompleted: true})
  init(ctx, {params, ready = false}) {
    const entityId = params['entity'];
    if (!ready) {
      mutateState(ctx, draft => {
        draft.count = (+entityId === draft.entity?.id ? draft.count : 0);
        draft.scheduledCount = (+entityId === draft.entity?.id ? draft.scheduledCount : 0);
        draft.entity = (+entityId === draft.entity?.id ? draft.entity : null);
        draft.records = [];
        draft.currentPage = params.qp_page;
        draft.isListReady = false;
        draft.unscheduledCount = null;
      });
    }
    const qp = _.cloneDeep(params);
    const mode = getMode(params['mode']);
    const options = {
      page: 1,
      qp_page: params.qp_page,
      calendarStart: ctx.getState().calendarStart,
      calendarEnd: ctx.getState().calendarEnd
    };
    if (ready && !options.qp_page){
      options.qp_page = ctx.getState().currentPage;
    }
    const total$ = this.recordService.retrieveRecordsCount({entity: entityId});
    const count$ = this.recordService.retrieveRecordsCount(_.omit(qp, ['mode']));
    const scheduledCount$ = this.recordService.retrieveRecordsCount(qp);
    const unscheduledCount$ = this.recordService.retrieveUnscheduledCount(qp)
    const user$ = this.userService.retrieveObject(this.localService.getUser(), []);
    const views$ = this.viewService.retrieveObjects({entity: entityId, space: this.localService.getSpace()}, ["users"]);
    const records$ = this.recordService.retrieveRecords(qp, options, ['originalCheckpointsets', 'space', 'value']);

    if (params['record_ids']) {
      const entity$ = this.entityService.retrieveModalListingMetadata(entityId, ['sharedSpaces', 'spaces']);
      return forkJoin([entity$, user$, records$]).pipe(mergeMap(([entity, user, records]: any) => {
        if (!entity.spaces.filter(space => space.id == this.localService.getSpace()).length) {
          this.autoSwitchSpace(entity, params);
        }
        mutateState(ctx, draft => {
          if (!_.isEqual(entity, draft.entity)) {
            draft.user = user;
            draft.entity = entity;
          }
          if (ready) {
            draft.records = [];
          }
          if (['dashboard', 'calendar'].includes(mode)) {
            draft.records = records;
          } else {
            const distinctRecords = _.uniqBy(draft.records.concat(records), (r: any) => r.id);
            draft.records = !qp['groupby'] ? distinctRecords : groupRecords(entity, distinctRecords, qp);
          }
          const recordIds = params['record_ids'].split(',')
          draft.allElementsLoaded = draft.records.filter((el) => el.id > -1).length === recordIds.length;
          draft.isListReady = true;
          draft.mode = mode;
        });
        return of([]);
      }))
    } else {
      const entity$ = this.entityService.retrieveListingMetadata(entityId, ['sharedSpaces', 'spaces']);
      return forkJoin([total$, count$, scheduledCount$, unscheduledCount$, views$, entity$, user$, records$]).pipe(
        mergeMap(([total, count, scheduledCount, unscheduledCount, views, entity, user, records]: any) => {
          if (!entity.spaces.filter(space => space.id == this.localService.getSpace()).length) {
            this.autoSwitchSpace(entity, params);
          }
          mutateState(ctx, draft => {
            if (!_.isEqual(entity, draft.entity)) {
              draft.user = user;
              draft.entity = entity;
            }
            if (ready) {
              draft.records = [];
            }
            if (['dashboard', 'calendar'].includes(mode)) {
              draft.records = records;
            } else {
              const distinctRecords = _.uniqBy(draft.records.concat(records), (r: any) => r.id);
              draft.records = !qp['groupby'] ? distinctRecords : groupRecords(entity, distinctRecords, qp);
            }
            draft.allElementsLoaded = draft.records.filter((el) => el.id > -1).length === count;
            const defaultView = {
              id: -1,
              title: "",
              isPublic: false,
              entity: entity.id,
              space: user.space,
              user: user.id,
              value: {},
              users: []
            }
            views.unshift(defaultView);
            draft.unscheduledCount = unscheduledCount;
            draft.scheduledCount = scheduledCount;
            draft.isListReady = true;
            draft.count = count;
            draft.total = total;
            draft.mode = mode;
            if(!_.isEqual(draft.view, views)){
              draft.views = views;
            }
            
          });
          return of([]);
        })
      );
    }
  }

  @Action(RecordsPage.RetrieveRecords, {cancelUncompleted: true})
  retrieveRecords(ctx, {params, page}) {
    const entityId = params['entity'];
    mutateState(ctx, draft => {
      draft.currentPage = page
    });
    let qp = _.cloneDeep(params);
    qp['entity'] = entityId;
    let mode = ctx.getState().mode;
    const options = {
      'page': page,
      'calendarStart': ctx.getState().calendarStart,
      'calendarEnd': ctx.getState().calendarEnd
    };
    return this.recordService.retrieveRecords(qp, options, ['originalCheckpointsets', 'space', 'value']).pipe(
      takeUntil(this.actions$.pipe(ofAction(RecordsPage.Init))),
      tap((records: any) => {
        mutateState(ctx, draft => {
          if (['dashboard', 'calendar'].includes(mode)) {
            draft.records = records;
          } else {
            draft.records = !qp['groupby'] ? draft.records.concat(records) : groupRecords(draft.entity, draft.records.concat(records), qp);
            draft.records = _.uniqWith(draft.records, (r1: any, r2: any) => {
              if (r1.id > -1 && r2.id > -1) {
                return r1.id == r2.id;
              }
            });
          }
          if(params['record_ids']){
            const recordIds = params['record_ids'].split(',')
            draft.allElementsLoaded = draft.records.filter((el) => el.id > -1).length === recordIds.length;
          }else{
            draft.allElementsLoaded = draft.records.filter((el) => el.id > -1).length == draft.scheduledCount;
          }

        });
      }),
      delay(0),
      tap(() => {
        mutateState(ctx, draft => {
          draft.isListReady = true;
        });
      })
    );
  }

  @Action(RecordsPage.ImportRecords)
  importRecords(ctx, { entityId, csvData }) {
    let rows = csvData.trim().split('\n');
    let header = rows[0].trim().split(',');
    let fields = ctx.getState().entity.blocks.map(b => b.fields);
    fields = [].concat.apply([], fields);
    header = header.map((fieldLabel) => {
      let field = _.find(fields, f => f.title === fieldLabel);
      if (field) {
        if (['OneToMany', 'InverseOneToMany', 'ManyToMany', 'InverseManyToMany', 'OneToOne', 'InverseOneToOne'].includes(field.type)) {
          return throwError(new Error('Le champ ' + fieldLabel + ' est non importable.'));
        } else {
          return field.id;
        }
      } else {
        return throwError(new Error('Le champ ' + fieldLabel + ' n\'existe pas.'));
      }
    });

    // throw header errors
    if (rows.length <= 1) return throwError(new Error('Aucunes données à importer.'));

    let records = [];
    for (let i = 1; i < rows.length; ++i) {
      let values = rows[i].trim().split(',');
      let newRecord = {};
      newRecord['entity'] = entityId;
      newRecord['value'] = {};
      for (let j = 0; j < values.length; ++j) {
        if (isNaN(header[j])) return header[j];
        newRecord['value'][header[j]] = values[j];
      }
      records.push(newRecord);
    }
    return this.recordService.createObject(records);
  }

  @Action(RecordsPage.ChangeCalendarDates)
  changeCalendarDates(ctx, { startDate, endDate, entityId, queryParams }) {
    mutateState(ctx, draft => {
      draft.calendarStart = startDate;
      draft.calendarEnd = endDate;
    });
    let qp = _.cloneDeep(queryParams);
    return this.store.dispatch(new RecordsPage.Init({'entity': entityId, ...qp}, true));
  }

  @Action(RecordsPage.SelectView)
  selectView(ctx, { view }) {
    mutateState(ctx, draft => {
      draft.latestView = view;
    });
  }

  @Action(RecordsPage.UpdateRecordFields)
  updateRecordFields(ctx, { record, fieldValueMapping }) {
    let newValue = {};
    Object.keys(fieldValueMapping).forEach((key) => {
      newValue[key] = fieldValueMapping[key];
    });
    return this.recordService.updateObject({ id: record.id, value: newValue }, ['value', 'tags', 'fixedCheckpoint', 'originalCheckpointsets']).pipe(
    tap((updatedRecord) => {
      mutateState(ctx, draft => {
        let index = draft.records.findIndex(x => x.id === record.id);
        draft.records[index]= updatedRecord;
      });
    }));
  }

  @Action(RecordsPage.RetrieveUnscheduledRecords)
  retrieveUnscheduledRecords(ctx, {queryParams, page}) {
    mutateState(ctx, draft => {
      draft.isUnscheduledReady = false;
      draft.unscheduledRecords = page === 1 ? [] : draft.unscheduledRecords;
    });
    const qp = _.cloneDeep(queryParams);
    const entity = ctx.getState().entity;

    const unscheduledCalendarRecords$ = this.recordService.retrieveUnscheduledRecords(qp, entity, page);

    return unscheduledCalendarRecords$.pipe(tap((records: any) => {
      mutateState(ctx, draft => {
        draft.unscheduledRecords = _.uniqBy(draft.unscheduledRecords.concat(records), (r: any) => r.id);
        draft.isUnscheduledReady = true;
      });
    }));
  }

  @Action(RecordsPage.RecordDrag)
  RecordDrag(ctx, {data}) {
    const entity = this.store.snapshot().app.recordsPage.entity;
    let currentIndex, targetIndex, currentRecord;
    const dateFieldId = (data.timeline && entity.startingDateField) ? entity.startingDateField.id : entity.endingDateField.id;
    targetIndex = data.timeline ? data.index : 0;
    mutateState(ctx, draft => {
      if (data.newDate && !data.record.value[dateFieldId]) {
        currentIndex = _.findIndex(ctx.getState().unscheduledRecords, (r: any) => r.id === data.record.id);
        currentRecord = _.nth(ctx.getState().unscheduledRecords, currentIndex);
        const updatedRecord = _.cloneDeep(currentRecord);
        _.remove(draft.unscheduledRecords, (r: any) => r.id === data.record.id);
        draft.unscheduledCount--;
        draft.scheduledCount++;
        if (data.newDate?.hasOwnProperty('start')) {
          if (entity.startingDateField) updatedRecord.value[entity.startingDateField.id] = data.newDate.start;
          updatedRecord.value[entity.endingDateField.id] = data.newDate.end;
        } else {
          updatedRecord.value[dateFieldId] = data.newDate;
        }
        if (data.timeline) {
          draft.records = [...draft.records.slice(0, data.index), updatedRecord, ...draft.records.slice(data.index)]
        } else {
          draft.records.unshift(updatedRecord)
        }
      }
      // move from calendar to unscheduled
      if (!data.newDate){
        currentIndex = _.findIndex(ctx.getState().records, (r: any) => r.id === data.record.id);
        currentRecord = _.nth(ctx.getState().records, currentIndex);
        draft.unscheduledRecords.unshift(data.record);
        _.remove(draft.records, (r: any) => r.id ===  data.record.id);
        draft.unscheduledCount++;
        draft.scheduledCount--;
      }
    });
    let fieldValueMapping = {};
    if (data.newDate?.hasOwnProperty('start')) {
      fieldValueMapping = {
        ...(entity.startingDateField && {[entity.startingDateField.id]: data.newDate.start}),
        [entity.endingDateField.id]: data.newDate.end,
        [data.groupByField]: data.groupByValue
      };
    } else {
      fieldValueMapping = {
        [dateFieldId]: data.newDate,
        [data.groupByField]: data.groupByValue
      };
    }
    let newValue = {};
    Object.keys(fieldValueMapping).forEach((key) => {
      newValue[key] = fieldValueMapping[key];
    });
    return this.recordService.updateObject({
      id: data.record.id,
      value: newValue
    }, ['value', 'tags', 'fixedCheckpoint', 'originalCheckpointsets']).pipe(
      tap((record: any) => {
          mutateState(ctx, draft => {
            if (data.newDate){
              draft.records = _.map(draft.records, (r: any) => r.id === record.id ? record : r);
            } else {
              draft.unscheduledRecords = _.map(draft.unscheduledRecords, (r: any) => r.id === record.id ? record : r);
            }
          });
        }
      ),
      catchError((error) => {
        mutateState(ctx, draft => {
          if (data.newDate && !data.record.value[entity.startingDateField.id]) {
            draft.scheduledCount--;
            draft.unscheduledCount++;
            draft.unscheduledRecords = [...draft.unscheduledRecords.slice(0, currentIndex), currentRecord, ...draft.unscheduledRecords.slice(currentIndex)]
            _.pullAt(draft.records, targetIndex);
          }
          // rollback from unscheduled to calendar
          if (!data.newDate){
            draft.scheduledCount++;
            draft.unscheduledCount--;
            draft.records = [...draft.records.slice(0, currentIndex), currentRecord, ...draft.records.slice(currentIndex)]
            _.remove(draft.unscheduledRecords, (r: any) => r.id ===  data.record.id);
          }
        });
        throw error;
      })
    )
  }

  @Action(RecordsPage.UpdateArchived)
  UpdateArchived(ctx, {params, archived}) {
    const entityId = params['entity'];
    let qp = _.cloneDeep(params);
    qp['entity'] = entityId;
      return this.recordService.updateObjects({entity: entityId, isArchived: archived},qp)
  }
  @Action(RecordsPage.ExecuteAction)
  executeAction(ctx, {queryParams,actionId, inp}) {
    const entityId = ctx.getState().entity.id;
    const options = {
      page: 1,
      qp_page: queryParams.qp_page,
      calendarStart: ctx.getState().calendarStart,
      calendarEnd: ctx.getState().calendarEnd,
    };
    queryParams = {...queryParams,entity:entityId}
    const action$ = this.actionService.createObject({entity: entityId,queryParams:queryParams, action: actionId, inp }, ['tags', 'fixedCheckpoint.checkpointset']);
    return action$.pipe(
      mergeMap((a) => {
        mutateState(ctx, draft => {
          draft.commandResult = a['commandResult'];
        });
        return this.store.dispatch(new RecordsPage.Init(queryParams,true));
      }),

      catchError((err) => {
        mutateState(ctx, draft => {
          draft.isCreating.value = false;
        });
        //If the error is related to the record itself instead of the action's result
        if (err.url && err.url.includes("/records/")){
          return of();
        }
        return throwError(err);
      })

    );
  }

  @Action(RecordsPage.UpdateHiddenViews)
    UpdateHiddenViews(ctx, { hiddenViews }) {
      mutateState(ctx, draft => {
        draft.hiddenViews = hiddenViews;
      });
    }

  private autoSwitchSpace(entity, params) {
      return this.store.dispatch(new Sider.SwitchSpace(entity.spaces[0].id)).pipe(tap(() => {
        this.toastr.info(translate("Changement automatique de l'espace"));
        return this.store.dispatch(new RecordsPage.Init(params));
      }));
  }

  @Selector()
  static getRecordsCount() {
    return createSelector([RecordsPageState], (state: RecordsPageState) => {
      const instance = state['app'].recordsPage;
      if(instance){
        return instance.count;
      }
      return 0;
    });
  }

  @Selector()
  static getViews(state: any): any[]{
    return state.views;
  }

  @Selector()
  static getHiddenViews(state: any): any[]{
    return state.hiddenViews;
  }

  @Selector()
  static getLatestViews(state: any){
    return state.latestView;
  }

  @Selector()
  static getEntity(state: any){
    return state.entity;
  }

  @Selector()
  static getMode(state: any): string{
    return state.mode;
  }

  @Selector()
  static getIsCreating(state: any): string{
    return state.isCreating;
  }
}
