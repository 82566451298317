import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getEntity',
    standalone: true
})
export class GetEntityPipe implements PipeTransform {

  transform(page): any {
    return page.entity ? page.entity : page.record.entity;
  }

}
