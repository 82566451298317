import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common'

@Pipe({
    name: 'smarttitle',
    standalone: true
})
export class SpecialTitleCasePipe extends TitleCasePipe implements PipeTransform {

  transform(value: any): any {
    const result = (value === value.toUpperCase()) ? value : value.charAt(0).toUpperCase() + value.slice(1);
    return result;
  }

}
