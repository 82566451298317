import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {RecordService} from "../../services/record.service";
import { NgbModal, NgbHighlight, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownButtonItem, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngxs/store';
import * as _ from 'lodash';
import { CheckDirective } from '../../directives/check.directive';
import { FormComponent } from '../form/form.component';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
declare var $: any;

@Component({
    selector: 'create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoDirective, NgIf, FormsModule, NgClass, ReactiveFormsModule, AutocompleteComponent, FormComponent, NgbHighlight, NgbDropdown, NgbDropdownToggle, CheckDirective, NgbDropdownMenu, NgFor, NgbDropdownButtonItem, NgbDropdownItem]
})
export class CreateComponent implements OnInit {
  @Input() entity;
  @Input() canCreate;
  @Input() field = null;
  @Input() checkpoint = null;
  @Input() hasMainField = true;
  @Input() isCreatingRecord;
  @Input() isCreatingCheckpoint;
  @Input() isTimeline = false;
  @Input() isCalendar = false;
  @Input() fixingEntities = null;

  @Output() createSubmit = new EventEmitter();
  @Output() cancelCreate = new EventEmitter();

  @ViewChild('creationFormRef') creationFormRef: ElementRef;
  
  valueControl;
  CreateModal;


  constructor(public recordService: RecordService, private modalService: NgbModal,public store: Store, public route: ActivatedRoute) { }

  ngOnInit() {
    this.valueControl = new UntypedFormControl('');
  }

  isCreationDisabled(): boolean {
    const isCreatingRecord = this.isCreatingRecord;
    const isCreatingCheckpoint = this.isCreatingCheckpoint;
  
    return (
      (isCreatingRecord?.type === 'record' && (isCreatingRecord?.fields?.includes(this.field.id) || isCreatingRecord?.value))
      || (isCreatingCheckpoint?.value && isCreatingCheckpoint?.type === 'checkpoint') 
      || (isCreatingRecord?.value && isCreatingRecord?.checkpoint && isCreatingRecord?.checkpoint === this.checkpoint?.id)
    );
  }

  onSubmit(value){
    this.createSubmit.emit(value)
  }

  onSubmitHandler() {
    if (!this.valueControl.value && this.hasMainField) return;
    this.createSubmit.emit(this.valueControl.value);
    if (!this.isTimeline) this.valueControl.setValue('');
  }

  openCreationModal(creationFormRef) {
    this.CreateModal = this.modalService.open(creationFormRef, {size: 'lg', backdrop: 'static'});
  }

  onCreationModalClose() {
    this.CreateModal.dismiss();
    this.cancelCreate.emit();
  }

  onAjouterClick(evt,creationFormRef) {
    if (this.entity.creationForm) {
      this.openCreationModal(creationFormRef);
    }
  }

  onCreationFormSubmit($event){
    this.createSubmit.emit($event);
  }

  onFixingRecordCreationSubmit($event) {
    this.createSubmit.emit($event);
  }

  handleCancelCreate() {
    this.cancelCreate.emit();
  }

}
