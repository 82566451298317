import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'error404',
    templateUrl: './error404.component.html',
    styleUrls: ['./error404.component.scss'],
    standalone: true,
    imports: [TranslocoDirective, RouterLink]
})
export class Error404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
