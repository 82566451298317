import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Store} from '@ngxs/store';
import {ActivatedRoute, Router} from '@angular/router';
import {EnvService} from '../../services/env.service';
import {setCookie} from '../../app.utils';
import {LocalService} from "../../services/local.service";
import {SsoGatewayService} from "../../services/sso-gateway.service";
import { NgIf } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'app-sso-gateway',
    templateUrl: './sso-gateway.component.html',
    styleUrls: ['./sso-gateway.component.scss'],
    standalone: true,
    imports: [TranslocoDirective, NgIf]
})
export class SsoGatewayComponent implements OnInit {

  constructor(private envService: EnvService, private ssoGatewayService: SsoGatewayService, private userService: UserService,
              private localService: LocalService, private store: Store, private route: ActivatedRoute, private router: Router) {
  }

  returnUrl: string;
  ssoGateway = null;
  ssoEndpoint = this.envService.ssoEndpoint;

  ngOnInit() {
    this.initGateway();
    this.userService.connectedUser().subscribe((connectedUser) => {
      if (connectedUser) this.router.navigateByUrl('/home');
      this.route.queryParams
        .subscribe(params => {
          this.returnUrl = params['returnUrl'] || '/home';
        });
    });
  }

  initGateway() {
    this.ssoGateway = this.route.snapshot.paramMap.get('ssoGatewayName') ? this.route.snapshot.paramMap.get('ssoGatewayName') : null;
    if (this.ssoGateway) {
      this.ssoGatewayService.retrieveObject(this.ssoGateway, []).subscribe(gateway => {
        this.ssoGateway = gateway;
        this.localService.setWorkspace(gateway.name);
      }, error => {
        this.ssoGateway = null;
        this.router.navigateByUrl('/login');
      });
    }

  }

}
